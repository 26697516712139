import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//containers
import { Container } from 'components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const BannerWrapper = styled.aside`
  display: flex;
  width: 100%;
  padding: 0;

  background-color: #575c61;
`;

export const BannerContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 5px;

  ${screen.md} {
    padding: 0;
  }

  ${screen.lg} {
    flex-direction: row;
  }
`;

export const Message = styled.div`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-default')};
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-wrap: balance;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;

  .only-desktop {
    display: none;
  }

  p {
    margin-top: 0;
  }

  ${screen.md} {
    br {
      display: none;
    }
  }

  ${screen.lg} {
    font-size: 16px;
    flex-direction: row;
    margin-top: 0px;

    .only-desktop {
      display: inline-block;
    }

    p {
      margin-top: 18px;
    }
  }

  ${screen.xxlg} {
    font-size: 18px;
  }

  a {
    color: rgb(${getToken('color-text-inverted')});
    font-family: ${getToken('platform-font-default')};
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;

    ${screen.lg} {
      font-size: 16px;
    }

    ${screen.xxlg} {
      font-size: 18px;
    }
  }
`;

export const LogosWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin: auto 8px;

  svg,
  path {
    fill: #ffffff;
  }
`;
