/* eslint-disable @next/next/no-img-element */
//components
import { SectionTitle } from '@/components/SectionTitle';
import { ContentPill } from '@/components/ContentPill';

//styles
import {
  SideFeaturesWrapper,
  MediaHolder,
  ContentHolder,
  Columns,
  CustomContainer,
} from './SideFeatures.styles';

//assets
import CarriersIcon from '@/public/images/icons/loadsmart-collection/010-sg-manage-carriers.svg';
import ServicesIcon from '@/public/images/icons/loadsmart-collection/011-sg-logistics-service.svg';

export function SideFeatures() {
  return (
    <>
      <SideFeaturesWrapper>
        <CustomContainer>
          <MediaHolder>
            <img
              src="/images/shipperguide/side-features/dashboard-features@2x.png"
              alt="ShipperGuide TMS Dashboard"
              width="543"
              height="539"
            />
          </MediaHolder>
          <ContentHolder>
            <SectionTitle
              title={<>Manage your carriers and find new capacity.</>}
              subtitle={`Supply Chain`}
              description={`Manage your carrier network, as well as have access to new carriers and instant capacity with Loadsmart's 100% tender acceptance.`}
              variant="left"
            />
            <Columns>
              <ContentPill
                content={{
                  icon: <CarriersIcon width="32" height="32" />,
                  title: 'Manage Carriers',
                  description: (
                    <>
                      Free interface to engage your carriers on spot and
                      contract bids.
                    </>
                  ),
                  items: [
                    { title: 'Upload Your Carriers' },
                    { title: 'Discover New Providers' },
                    { title: 'Carrier Bids With No Charge' },
                  ],
                }}
              />
              <ContentPill
                content={{
                  icon: <ServicesIcon width="32" height="32" />,
                  title: 'Logistics Service',
                  description: (
                    <>Instantly quote & book shipments with Loadsmart.</>
                  ),
                  items: [
                    { title: 'Guaranteed Capacity' },
                    { title: 'Backstop Carrier Failure' },
                    { title: 'Line of Credit' },
                  ],
                }}
              />
            </Columns>
          </ContentHolder>
        </CustomContainer>
      </SideFeaturesWrapper>
    </>
  );
}
