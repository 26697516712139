/* eslint-disable @next/next/no-img-element */
//components
import { Container } from '@/components/Grid';

//miranda button
import {
  MirandaBrandButton,
  MirandaHollowButton,
} from '@/components/Button/BaseButton';

//styles
import {
  SideCTAWrapper,
  ImageSpacer,
  ContentHolder,
  FooterContent,
  Title,
  Description,
} from './SideCTA.styles';

export function SideCTA({ content }) {
  return (
    <>
      <SideCTAWrapper>
        <Container>
          <ImageSpacer role="presentation">
            <img src="/images/managed-transportation/mt-side-xxlg.jpg" alt="" />
          </ImageSpacer>
          <ContentHolder>
            <Title as="h2">
              The freight market is dynamic,{' '}
              <strong>are you agile enough to take advantage of it?</strong>
            </Title>
            <Description as="p">
              We combine human and machine intelligence to help you stay on top
              of a dynamic market. With unparalleled market visibility,
              Loadsmart creates a robust network strategy that balances
              near-term predictability and long-term adaptability.
            </Description>
            <FooterContent>
              <MirandaBrandButton as="a" href="#hubspot-form">
                Schedule your assessment
              </MirandaBrandButton>
              <MirandaHollowButton
                as="a"
                href="https://lp.loadsmart.com/en/data-driven-optimization-reports-for-shippers?utm_source=loadsmart&utm_medium=hyperlink&utm_content=market-report-section&utm_campaign="
                target="_blank"
                rel="noreferrer noopener"
              >
                Download the latest market report
              </MirandaHollowButton>
            </FooterContent>
          </ContentHolder>
        </Container>
      </SideCTAWrapper>
    </>
  );
}
