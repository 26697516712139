import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//components
import { Container, InvertedSection, Text } from '@/components/Grid';

export const FormBackground = styled.div`
  padding: 20px 0 40px;
  background: url(/images/shipperguide/demo/demo-background.svg) no-repeat right
    bottom;
  background-color: ${({ theme }) => theme.color.neutral.lighter3};
  background-size: 60%;

  ${screen.md} {
    background-size: unset;
    padding: 20px 0 40px;
  }

  ${screen.lg} {
    padding: 30px 0 60px;
  }

  ${screen.xxlg} {
    padding: 40px 0 80px;
  }
`;

export const ContainerLogo = styled(Container)`
  display: flex;
  align-items: center;
  color: rgb(${getToken('color-primary-60')});
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-regular')};

  font-size: 22px;
`;

export const TopLogoWrapper = styled.div`
  width: fit-content;
  align-items: baseline;
  margin: 0;
  width: 158px;
  height: auto;
  margin: ${getToken('spacing-10')} 0;

  svg {
    fill: rgb(${getToken('color-primary-60')});
  }
`;

export const ContainerForm = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  height: 100%;
  gap: 0px;

  ${screen.md} {
    gap: 100px;
  }

  ${screen.lg} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
`;

export const ContentHolder = styled.div`
  ${screen.lg} {
    width: 50%;
  }
`;

export const MediaHolder = styled.div`
  margin: auto;

  ${screen.lg} {
    padding-left: 20px;
    width: 50%;

    & > div {
      width: calc(100% + 60px) !important;
    }
  }
`;

export const Title = styled.div`
  color: rgb(${getToken('color-primary-100')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 28px;
  line-height: 43.7px;

  strong {
    color: rgb(${getToken('color-brand')});
  }

  ${screen.md} {
    font-size: 38px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 66.7px;
    padding: 40px 0 0;
  }
`;

export const SubTitle = styled.p`
  color: rgb(${getToken('color-primary-100')});
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-bold')};

  font-size: 14px;
  line-height: 22.4px;

  ${screen.md} {
    font-size: 16px;
    line-height: 25.6px;
  }

  ${screen.xxlg} {
    font-size: 24px;
    line-height: 28.8px;

    margin: 80px 0 40px;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  ${screen.md} {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  ${screen.lg} {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
`;

export const ListItem = styled.li`
  display: flex;
  margin: 0 0 33px;

  p {
    margin: 0;
    color: rgb(${getToken('color-primary-100')});
    font-family: ${getToken('platform-font-default')};
    font-weight: ${getToken('font-weight-regular')};

    font-size: 14px;
    line-height: 22.4px;

    ${screen.md} {
      font-size: 16px;
      line-height: 25.6px;
    }

    ${screen.xxlg} {
      font-size: 20px;
      line-height: 24px;
    }
  }

  svg {
    width: 32px;
    height: 32px;
    margin: 0 27px 0 0;
  }
`;

export const CTAHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: center;
  text-align: center;

  a {
    width: 100%;
    margin: 10px;
  }

  ${screen.md} {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;

    a {
      width: auto;
    }
  }
`;
