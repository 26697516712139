import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const BaseButton = styled.button`
  margin: 12px 0;
  border: none;
  background: ${({ theme }) => theme.color.greens.loadsX400};
  color: #ffffff;
  border-radius: 4px;
  padding: 12px 24px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;
  justify-content: center;

  ${screen.md} {
    font-size: 12px;
    line-height: 14px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const UpperButton = styled(BaseButton)`
  text-transform: uppercase;
`;

export const GhostButton = styled(UpperButton)`
  background-color: transparent;
  border: 1px solid #ffffff;
`;

export const MirandaButton = styled(BaseButton)`
  display: inline-flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 14px;

  color: rgb(${getToken('color-text-inverted')});
  font-size: 15px;
  line-height: 16px;
  font-weight: ${getToken('font-weight-medium')};
  text-transform: none;

  background: rgb(${getToken('color-background-inverted')});
  border: 2px solid rgb(${getToken('color-background-inverted')});
  border-radius: 4px;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active {
    background: rgb(${getToken('color-primary-100')});
    border-color: rgb(${getToken('color-primary-100')});
    transition: all 0.2s ease-in-out;
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-primary-100')});
  }
`;

export const MirandaHollowButton = styled(MirandaButton)`
  background: transparent;
  border: 2px solid rgb(${getToken('color-text-inverted')});

  &:hover,
  &:active {
    background: rgb(${getToken('color-primary-100')});
    border-color: rgb(${getToken('color-primary-100')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-text-inverted')});
  }
`;

export const MirandaSecondaryButton = styled(MirandaButton)`
  color: rgb(${getToken('color-text-inverted')});
  background: rgb(${getToken('color-primary-60')});
  border: 2px solid rgb(${getToken('color-primary-60')});

  &:hover,
  &:active {
    background: rgb(${getToken('color-primary-100')});
    border-color: rgb(${getToken('color-primary-100')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-primary-60')});
  }
`;

export const MirandaBrandButton = styled(MirandaButton)`
  color: rgb(${getToken('color-primary-100')});
  background: rgb(${getToken('color-brand')});
  border: 2px solid rgb(${getToken('color-brand')});

  &:hover,
  &:active {
    color: rgb(${getToken('color-primary-60')});
    background: rgb(${getToken('color-background-tertiary')});
    border-color: rgb(${getToken('color-primary-60')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-background-tertiary')});
  }
`;

export const MirandaSecondaryInvertedButton = styled(MirandaButton)`
  color: rgb(${getToken('color-text-primary')});
  background: rgb(${getToken('color-background-primary')});
  border: 2px solid rgb(${getToken('color-background-primary')});
  &:hover,
  &:active {
    color: rgb(${getToken('color-primary-60')});
    background: rgb(${getToken('color-background-tertiary')});
    border-color: rgb(${getToken('color-background-tertiary')});
  }
  &:focus {
    outline: 1px solid rgb(${getToken('color-primary-60')});
  }
`;

export const MirandaHollowSecondaryButton = styled(MirandaButton)`
  color: rgb(${getToken('color-primary-100')});
  background: rgb(${getToken('color-background-primary')});
  border: 2px solid rgb(${getToken('color-primary-100')});

  &:hover,
  &:active {
    color: rgb(${getToken('color-primary-60')});
    background: rgb(${getToken('color-background-tertiary')});
    border-color: rgb(${getToken('color-primary-60')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-primary-100')});
  }
`;

export const MirandaGradientButton = styled(MirandaButton)`
  border: 2px solid rgb(${getToken('color-primary-60')});
  color: rgb(${getToken('color-text-primary')});
  background: transparent;
  position: relative;
  z-index: 2;

  span {
    z-index: 3;
  }

  &::before {
    content: '';
    display: block;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background: linear-gradient(45.01deg, #00d7d7 23.29%, #84f11e 88.12%);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 1;
    transition: all 0.1s ease-in-out;
  }

  &:hover,
  &:active {
    background: rgb(${getToken('color-brand')});
    border-color: rgb(${getToken('color-primary-100')});
    transition: all 0.1s ease-in-out;

    &::before {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-neutral-0')});
  }

  &.noborder {
    border-color: transparent !important;
    &:hover,
    &:active,
    &:focus {
      border-color: transparent !important;
    }
  }
`;

export const MirandaBrandHollowButton = styled(MirandaButton)`
  color: rgb(${getToken('color-brand')});
  background: transparent;
  border: 2px solid rgb(${getToken('color-brand')});

  &:hover,
  &:active {
    color: rgb(${getToken('color-primary-60')});
    background: rgb(${getToken('color-background-tertiary')});
    border-color: rgb(${getToken('color-primary-60')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-background-tertiary')});
  }
`;
