//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '/containers-m/';

// context containers
import { Form } from './containers/Form';

import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

function Demo() {
  return (
    <PageWrapper>
      <Navigation />
      <Form />
      <Footer />
    </PageWrapper>
  );
}

export default Demo;
export { Demo };
