import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const ContentPillWrapper = styled.article`
  display: flex;
  flex-direction: column;

  margin: 8px auto;
`;

export const ContentPillIcon = styled.span``;

export const ContentPillLink = styled.a`
  display: inline-flex;
  width: auto;
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-60')});
  margin: 8px 0;
  font-size: 14px;
  line-height: 22.4px;
  text-decoration: underline;

  span {
    position: relative;
    padding-right: 30px;

    &::after {
      content: ' \\2192 ';
      display: block;
      width: 22px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;

      color: rgb(${getToken('color-brand')});
    }
  }
`;

export const ContentPillTitle = styled.h3`
  display: inline-flex;
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-100')});
  margin: 8px 0;
  font-size: 16px;
  line-height: 25.6px;

  strong {
    font-weight: ${getToken('font-weight-medium')};
    color: rgb(${getToken('color-primary-60')});
  }

  .inner-tag {
    align-self: flex-start;
  }
`;

export const ContentPillDescription = styled.p`
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-60')});
  font-size: 14px;
  line-height: 19.6px;
  margin: 0;

  a {
    color: rgb(${getToken('color-brand')});
    text-decoration: underline;
  }
`;

export const ContentPillList = styled.ul`
  padding: 0;
  margin: 24px 0;
  list-style: none;
`;
export const ContentPillItem = styled.li`
  display: flex;
  gap: 8px;
  align-items: start;

  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-60')});
  font-size: 14px;
  line-height: 19.6px;
  margin: 12px 0;

  svg {
    margin-top: 2px;
  }
`;
