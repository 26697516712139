import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 599px;
  text-align: center;

  background-color: rgb(${getToken('color-background-secondary-inverted')});
  background-image: url(/${(props) => props.bg.small});
  background-position: center center;
  background-size: cover;

  padding: 80px 0 38px;

  &.biggerBg {
    min-height: 806px;
  }

  ${screen.md} {
    min-height: 481px;

    background-image: url(/${(props) => props.bg.medium});

    padding: 80px 0 68px;

    &.biggerBg {
      min-height: 678px;

      padding: 80px 0 38px;
    }
  }

  ${screen.lg} {
    min-height: 620px;

    background-image: url(/${(props) => props.bg.large});
  }

  ${screen.xxlg} {
    min-height: 860px;

    background-image: url(/${(props) => props.bg.xxlarge});

    padding: 80px 0 88px;

    &.biggerBg {
      min-height: 866px;
      padding: 80px 0;
    }
  }
`;

export const Title = styled.h1`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-medium')};
  line-height: 52px;
  font-size: 48px;
  width: 100%;
  margin-bottom: 18px;

  &.biggerBg {
    line-height: 47.5px;
    font-size: 38px;
  }

  ${screen.md} {
    line-height: 72.5px;
    font-size: 58px;

    br {
      display: none;
    }
  }

  ${screen.xxlg} {
    margin: 38px 0;

    &.biggerBg {
      margin: 32px 0;
      line-height: 60px;
      font-size: 48px;
    }
  }
`;

export const Paragraph = styled.p`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 20px;
  line-height: 39px;
  margin: 0 auto;

  ${screen.md} {
    margin-bottom: 36px;
  }

  ${screen.lg} {
    max-width: 751px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    max-width: 1130px;
  }

  &.small {
    margin: 0 auto 30px;
    font-size: 14px;
    line-height: 21px;

    ${screen.lg} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
