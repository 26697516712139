import styled, { css } from 'styled-components';
import { screen } from '@/helpers/screen';

export const HighlightedIcon = (props) => <span {...props} />;

export const FeatureIcon = styled.span`
  color: #6c8694;
`;

export const responsiveIconStyle = css`
  width: 48px;
  height: 48px;

  ${screen.lg} {
    width: 66px;
    height: 66px;
  }
`;

export const FeatureTitle = styled.h4`
  margin: 6px 0;

  font-size: 13px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;

  ${screen.md} {
    margin: 10px 0;

    font-size: 14px;
    line-height: 16px;
  }

  ${screen.lg} {
    font-size: 17px;
    line-height: 20px;
  }

  ${screen.xxlg} {
    margin: 12px 0;

    font-size: 21px;
    line-height: 25px;
  }
`;

export const FeatureDescription = styled.p`
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  a {
    text-decoration: underline;
    color: rgb(3, 124, 94);
    font-weight: bold;
  }

  ${screen.md} {
    line-height: 18px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 38px;
  }
`;
