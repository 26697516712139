// components
import { Container } from 'components/Grid';

// styles
import {
  CustomersLogosWrapper,
  LogosList,
  LogoScottsMiracle,
  LogoBackyard,
  LogoAthletic,
  LogoSteeleSolutions,
  LogoOlympicSteel,
  Title,
} from './PartnersIcons.styles';

export function PartnersIcons() {
  return (
    <CustomersLogosWrapper>
      <Container>
        <Title>Trusted by 200+ shippers</Title>
        <LogosList>
          <LogoScottsMiracle
            src={`/images/shipperguide/partners-icons/scotts-miracle-icon.png`}
            alt="Scotts Miracle Gro"
            width="208"
            height="32"
          />
          <LogoBackyard
            src={`/images/shipperguide/partners-icons/backyard-icon.png`}
            alt="Backyard"
            width="140"
            height="50"
          />
          <LogoAthletic
            src={`/images/shipperguide/partners-icons/athletic-icon.png`}
            alt="Athletic"
            width="129"
            height="40"
          />
          <LogoSteeleSolutions
            src={`/images/shipperguide/partners-icons/steele-solutions-icon.png`}
            alt="Steele Solutions"
            width="131"
            height="40"
          />
          <LogoOlympicSteel
            src={`/images/shipperguide/partners-icons/olympic-steel-icon.png`}
            alt="Olympic Steel"
            width="204"
            height="40"
          />
        </LogosList>
      </Container>
    </CustomersLogosWrapper>
  );
}
