import styled from 'styled-components';

//components
import { BaseButton } from '@/components/Button/BaseButton';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: rgb(${getToken('color-background-tertiary')});

  padding: 50px 0;

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const SessionTitle = styled.h1`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;
  margin: 24px auto;
`;

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 32px 0;

  ${screen.md} {
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 50px 0;

    &.single-column {
      grid-template-columns: minmax(auto, 50%);
    }
  }
  ${screen.xxlg} {
    grid-gap: 20px;
  }
`;
export const ContentItem = styled.article`
  display: flex;
  flex-direction: column;
  marging: 0 auto;
`;

export const Title = styled.h2`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 26px;
  line-height: 39px;
  margin: 12px auto;
`;

export const Paragraph = styled.p`
  color: rgb(${getToken('color-text-tertiary')});
  font-family: ${getToken('platform-font-title')};
  font-size: 14px;
  line-height: 21px;
  margin: 12px auto;

  ${screen.lg} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const CTA = styled(BaseButton)`
  background: rgb(${getToken('color-background-inverted')});
  font-family: ${getToken('platform-font-default')};
  border: 1px solid rgb(${getToken('color-background-primary')});
`;
