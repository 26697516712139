import styled from 'styled-components';
import { screen } from '@/helpers/screen';
import { Section } from '@/components/Grid/Section';

const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  ${screen.md} {
    width: 218px;
  }

  ${screen.lg} {
    width: 312px;
  }

  ${screen.xxlg} {
    width: 440px;
  }
`;

export const Feature = styled(BaseContainer)`
  margin: 8px 0;

  ${screen.md} {
    margin: 8px 5px;
  }

  ${screen.lg} {
    margin: 20px 8px;
  }

  ${screen.xxlg} {
    margin: 25px 10px;
  }
`;

export const FeatureGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${screen.md} {
    margin: 0 -5px;
  }

  ${screen.lg} {
    margin: 0 -8px;
  }

  ${screen.xxlg} {
    margin: 0 -10px;
  }
`;

export const FeatureSection = styled(Section)`
  margin-top: 38px;

  ${screen.md} {
    margin-top: 47px;
  }

  ${screen.lg} {
    margin-top: 38px;
  }

  ${screen.xxlg} {
    margin-top: 58px;
  }
`;

export const StyledFeatureGroup = styled(FeatureGroup)`
  padding: 5px 0 0;

  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -35px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 100%;
  margin: 10px auto 10px;

  ${screen.md} {
    width: 30%;
    margin: 10px auto 20px;
  }

  ${screen.lg} {
    margin: 10px auto 25px;
  }

  ${screen.xxlg} {
    margin: 10px auto 35px;
  }
`;
