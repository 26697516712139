import { useEffect } from 'react';
import PropTypes from 'prop-types';

//assets
import {
  setHubspotFormIntegration,
  DisclaimerText,
} from '@/vendor/HubspotFormIntegration';

import {
  Section,
  Wrapper,
  TitleContainer,
  FormContainer,
} from './HubspotForm.styles';

const HubspotForm = ({
  children,
  portalId,
  formId,
  sfdcCampaignId,
  target = 'integration-hubspot',
  disclaimer = false,
}) => {
  useEffect(() => {
    setHubspotFormIntegration({
      portalId,
      formId,
      sfdcCampaignId,
      target: `#${target}`,
    });
  }, [formId, portalId, sfdcCampaignId, target]);

  return (
    <Section id="hubspot-form">
      <Wrapper>
        <TitleContainer className="Title">{children}</TitleContainer>
        <FormContainer>
          <div id={target} />
          {disclaimer && <DisclaimerText />}
        </FormContainer>
      </Wrapper>
    </Section>
  );
};

HubspotForm.propTypes = {
  children: PropTypes.node,
  portalId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  sfdcCampaignId: PropTypes.string.isRequired,
  target: PropTypes.string,
};

HubspotForm.defaultProps = {
  children: null,
  target: 'integration-hubspot',
};

export default HubspotForm;
export { HubspotForm };
