import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';
import { Title } from '@/components/Grid';
import { Paragraph1, Paragraph2, Subtitle } from '@/components/Text';

export const WideCalculatorWrapper = styled.section`
  display: flex;
  width: 100%;
  background: rgb(${getToken('color-background-primary')});
  padding: ${getToken('spacing-20')} 0;
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: rgb(${getToken('color-background-tertiary')});
  padding: ${getToken('spacing-10')} ${getToken('spacing-4')};

  border-radius: ${getToken('border-radius-l')};
  box-shadow: ${getToken('elevation-2')};

  ${screen.md} {
    padding: ${getToken('spacing-10')} ${getToken('spacing-8')};
  }

  ${screen.lg} {
    padding: ${getToken('spacing-10')};
  }

  ${screen.xxlg} {
    padding: ${getToken('spacing-20')};
  }
`;

export const SectionHeader = styled.div`
  margin: 0 auto 40px;
`;

export const TitleCustom = styled(Title)`
  text-align: center;
  font-weight: ${getToken('font-weight-medium')};
  font-size: 20px;
  line-height: 30px;
  color: rgb(${getToken('color-text-secondary')});

  ${screen.lg} {
    font-size: 26px;
    line-height: 39px;
  }
  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const ParagraphCustom = styled(Paragraph1)`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-regular')};
  text-wrap: balance;

  text-align: center;
`;

export const Paragraph2Custom = styled(Paragraph2)`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 21px;
  color: rgb(${getToken('color-text-secondary')});
  text-wrap: balance;
  text-align: center;

  ${screen.lg} {
    text-align: left;
  }
`;

export const Paragraph3Custom = styled(Paragraph2Custom)`
  font-weight: ${getToken('font-weight-medium')};
  color: rgb(${getToken('color-text-placeholder')});
  margin: 20px 0 0;
`;

export const SubtitleCustom = styled(Subtitle)`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 16px;
  line-height: 24px;
  color: rgb(${getToken('color-text-secondary')});
  text-align: center;

  ${screen.md} {
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SliderContainer = styled.div`
  margin: auto;
  max-width: 100%;
  ${screen.md} {
    max-width: 330px;
  }
  ${screen.lg} {
    margin: 0;
    max-width: auto;
  }
`;

export const CalculatorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 34px;
  text-align: center;
  align-items: center;

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    grid-template-columns: auto 490px;
  }

  ${screen.xxlg} {
    grid-template-columns: auto 690px;
    gap: 100px;
  }
`;

export const TableWrapper = styled.div`
  background: rgb(${getToken('color-background-primary')});
  border-radius: ${getToken('border-radius-l')};
  padding: 16px;

  & > div:first-child {
    text-align: center;

    ${screen.lg} {
      text-align: left;
    }
  }

  ${screen.md} {
    padding: 40px;
  }
`;

export const FormWrapper = styled.div`
  form {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;

    ${screen.xxlg} {
      grid-template-columns: 1fr 1fr;
    }
  }

  fieldset {
    text-align: left;
  }

  .hs-input {
    background: rgb(${getToken('color-background-primary')});
    border: 1px solid rgb(${getToken('color-border')});
    border-radius: ${getToken('border-radius-s')};
    padding: ${getToken('spacing-2')} ${getToken('spacing-2')};

    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-regular')};
    font-size: 14px;
    line-height: 21px;
    color: rgb(${getToken('color-text-primary')});
  }

  label {
    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-regular')};
    font-size: 14px;
    line-height: 21px;
    color: rgb(${getToken('color-text-secondary')});
  }

  .hs-button {
    padding: ${getToken('spacing-2')} ${getToken('spacing-12')};
    background: rgb(${getToken('color-background-inverted')});
    border: 1px solid rgb(${getToken('color-border')});
    border-radius: ${getToken('border-radius-s')};

    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-bold')};
    font-size: 14px;
    line-height: 21px;
    color: rgb(${getToken('color-text-inverted')});
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      background: rgb(${getToken('color-primary-60')});
      transition: all 0.1s ease-in-out;
    }
  }

  .submitted-message {
    padding: 8px 20px;
    border: 1px solid rgb(${getToken('color-border')});

    p {
      font-family: ${getToken('font-family-default')};
      font-weight: ${getToken('font-weight-regular')};
      font-size: 14px;
      line-height: 21px;
      color: rgb(${getToken('color-text-secondary')});

      text-align: center;

      a {
        text-decoration: underline;
        font-weight: ${getToken('font-weight-bold')};
        color: rgb(${getToken('color-accent-100')});
      }

      ${screen.md} {
        text-align: left;
      }
    }
  }
`;

export const FormHeader = styled.div`
  margin: ${getToken('spacing-8')} 0 ${getToken('spacing-4')};
`;

export const FormTitle = styled(SubtitleCustom)`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  text-align: center;

  ${screen.lg} {
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const SliderWrapper = styled.div`
  .rangeslider {
    box-shadow: none;
    background: rgb(${getToken('color-background-secondary-inverted')});
    height: 8px;

    &__fill {
      background: rgb(${getToken('color-brand')});
    }

    &__handle {
      box-shadow: none;
      border: none;
      background: rgb(${getToken('color-brand')});
      width: 24px;
      height: 24px;

      &::after {
        display: none;
      }
    }
  }
`;

//labels
export const LabelAmount = styled.span`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 48px;
  line-height: 60px;
  color: rgb(${getToken('color-text-primary')});
  text-align: left;

  ${screen.md} {
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const LabelEstimated = styled.span`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};

  font-size: 28px;
  line-height: 39.5px;
  color: rgb(${getToken('color-text-placeholder')});
  text-align: left;

  ${screen.md} {
    font-size: 38px;
    line-height: 47.5px;
  }
`;

export const LabelSaving = styled(LabelEstimated)`
  color: rgb(${getToken('color-brand')});
  font-size: 26px;
  line-height: 39px;

  span {
    color: rgb(${getToken('color-text-placeholder')});
  }

  ${screen.xxlg} {
    font-size: 38px;
    line-height: 47.5px;
  }
`;
