import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

import { MirandaButton } from '@/components/Button/BaseButton';

export const SectionWrapper = styled.section`
  padding: 60px 0;
  background-color: ${({ theme }) => theme.color.neutral.lighter3};
  text-align: center;

  ${screen.lg} {
    padding: 100px 0;
  }
`;

export const TitleWrapper = styled.div`
  margin: 8px 0 48px;

  ${screen.md} {
    margin: 12px 0 48px;
  }

  ${screen.lg} {
    margin: 16px 0 48px;
  }

  ${screen.xxlg} {
    margin: 16px 0 64px;
  }
`;

export const Picture = styled.picture`
  display: inline-block;
  box-sizing: border-box;
  margin: auto;
  max-width: 329px;

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }

  ${screen.md} {
    max-width: 690px;
  }

  ${screen.lg} {
    max-width: 826px;
  }

  ${screen.xxlg} {
    max-width: 1086px;
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 60px;

  ${screen.md} {
    gap: 32px;
    flex-direction: row;
  }

  ${screen.xxlg} {
    margin-bottom: 80px;
  }
`;

export const StyledButton = styled(MirandaButton)`
  padding: 16px 52px;
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};

  ${screen.md} {
    padding: 16px 40px;
  }
`;

export const StyledButtonHollow = styled(MirandaButton)`
  padding: 16px 24px;
  color: rgb(${getToken('color-primary-100')});
  background-color: transparent;
  border: 2px solid rgb(${getToken('color-brand')});
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-bold')};

  &:hover,
  &:active {
    color: rgb(${getToken('color-primary-60')});
    background: rgb(${getToken('color-background-tertiary')});
    border-color: rgb(${getToken('color-primary-60')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-primary-100')});
  }
`;
