import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const CustomersLogosWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 36px 0;

  text-align: center;

  background-color: rgb(${getToken('color-background-primary')});

  ${screen.md} {
    padding: 62px 0;
  }
  ${screen.xxlg} {
    padding: 62px 0 72px;
  }
`;

export const LogosList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${screen.md} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const Title = styled.h3`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-primary-60')});
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 0 0 26px;

  ${screen.md} {
    margin: 0 0 42px;
    font-size: 16px;
    line-height: 25px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 32px;
  }

  ${screen.xxlg} {
    margin: 0 0 32px;
    font-size: 20px;
    line-height: 32px;
  }
`;

const Logo = styled.img`
  box-sizing: border-box;
  max-width: 100%;
  height: auto;

  &:nth-child(1) {
    margin: 0 16px 20px 0;
  }
  &:nth-child(2) {
    margin: 0 16px 20px;
  }
  &:nth-child(3) {
    margin: 0 0 20px 16px;
  }
  &:nth-child(4) {
    margin: 0 16px 0 0;
  }
  &:nth-child(5) {
    margin: 0 0 0 16px;
  }

  ${screen.md} {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      margin: auto;
    }
  }
`;

export const LogoScottsMiracle = styled(Logo)`
  width: 80px;

  ${screen.md} {
    width: 100px;
  }
  ${screen.lg} {
    width: 150px;
  }
  ${screen.xxlg} {
    width: 208px;
  }
`;

export const LogoBackyard = styled(Logo)`
  width: 70px;

  ${screen.md} {
    width: 80px;
  }
  ${screen.lg} {
    width: 100px;
  }
  ${screen.xxlg} {
    width: 140px;
  }
`;

export const LogoAthletic = styled(Logo)`
  width: 70px;

  ${screen.md} {
    width: 80px;
  }
  ${screen.lg} {
    width: 100px;
  }
  ${screen.xxlg} {
    width: 129px;
  }
`;

export const LogoSteeleSolutions = styled(Logo)`
  width: 80px;

  ${screen.md} {
    width: 100px;
  }
  ${screen.xxlg} {
    width: 131px;
  }
`;

export const LogoOlympicSteel = styled(Logo)`
  width: 90px;

  ${screen.md} {
    width: 100px;
  }
  ${screen.lg} {
    width: 150px;
  }
  ${screen.xxlg} {
    width: 204px;
  }
`;

export const Content = styled.h3`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-tertiary')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 26px;
  line-height: 39px;
  text-align: center;

  margin: 36px auto;

  br:nth-child(2) {
    display: none;
  }

  ${screen.md} {
    br:nth-child(1) {
      display: none;
    }
    br:nth-child(2) {
      display: inline-block;
    }
  }

  ${screen.lg} {
    margin-top: 50px;

    br:nth-child(2) {
      display: none;
    }
  }

  ${screen.xxlg} {
    margin-top: 36px;
  }
`;
