import styled from 'styled-components';

import { Container } from '@/components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

export const FormWrapper = styled.section`
  background: ${(props) =>
    props.theme === 'light'
      ? `rgb(${getToken('color-background-tertiary')})`
      : `rgb(${getToken('color-background-inverted')})`};

  padding: 60px 0;

  ${screen.lg} {
    padding: 80px 0;
  }
  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const FormDescription = styled.div`
  color: ${(props) =>
    props.theme === 'light'
      ? `rgb(${getToken('color-text-primary')})`
      : `rgb(${getToken('color-text-inverted')})`};

  text-align: center;

  ${screen.md} {
    text-align: left;
  }
`;

export const FormTitle = styled.h2`
  font-family: ${getToken('platform-font-title')};
  color: ${(props) =>
    props.theme === 'light'
      ? `rgb(${getToken('color-success-100')})`
      : `rgb(${getToken('color-brand')})`};

  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  text-wrap: balance;

  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }
  ${screen.xxlg} {
    font-size: ${(props) => (props.theme === 'light' ? `58px` : `48px`)};
    line-height: ${(props) => (props.theme === 'light' ? `73px` : `60px`)};
  }
`;

export const FormSubtitle = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: ${(props) =>
    props.theme === 'light'
      ? `rgb(${getToken('color-text-primary')})`
      : `rgb(${getToken('color-text-inverted')})`};

  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-wrap: balance;

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
    max-width: 510px;
  }
  ${screen.xxlg} {
    font-size: ${(props) => (props.theme === 'light' ? `38px` : `26px`)};
    line-height: ${(props) => (props.theme === 'light' ? `48px` : `39px`)};
    max-width: 635px;
  }
`;

export const CustomContainer = styled(Container)`
  display: grid;
  gap: 24px;

  ${screen.md} {
    grid-template-columns: 1fr 330px;
  }

  ${screen.lg} {
    grid-template-columns: 1fr 417px;
  }
  ${screen.xxlg} {
    grid-template-columns: 1fr 440px;
  }
`;

export const HubspotFormContainer = styled.div`
  margin: auto;
  width: 100%;

  color: ${(props) =>
    props.theme === 'light'
      ? `rgb(${getToken('color-text-primary')})`
      : `rgb(${getToken('color-text-inverted')})`};

  label,
  legend {
    margin: 4px 0 0;
    display: inline-block;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) =>
      props.theme === 'light'
        ? `rgb(${getToken('color-text-primary')})`
        : `rgb(${getToken('color-text-inverted')})`};
  }

  legend {
    font-size: 14px;
    line-height: 20px;
  }

  .input {
    margin: 0 10px 0 0 !important;
  }

  .hs-form-field {
    margin-bottom: 16px;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='number'],
  select,
  textarea {
    width: 100% !important;
    height: 48px;

    border: 1px solid #fff;
    border-radius: 3px;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgb(${getToken('color-text-primary')});
    padding: 4px 8px;
  }

  textarea {
    height: unset;
    min-height: 48px;
  }

  input[type='submit'] {
    display: inline-flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 14px;
    width: 100%;

    font-family: ${getToken('platform-font-default')};
    color: ${(props) =>
      props.theme === 'light'
        ? `rgb(${getToken('color-text-inverted')})`
        : `rgb(${getToken('color-text-primary')})`};
    font-size: 16px;
    line-height: 16px;
    font-weight: ${getToken('font-weight-medium')};
    text-transform: none;

    background: ${(props) =>
      props.theme === 'light'
        ? `rgb(${getToken('color-background-inverted')})`
        : `rgb(${getToken('color-brand')})`};
    border: 2px solid
      ${(props) =>
        props.theme === 'light'
          ? `rgb(${getToken('color-background-inverted')})`
          : `rgb(${getToken('color-brand')})`};

    border-radius: 4px;
    transition: all 0.1s ease-in-out;

    cursor: pointer;

    &:hover,
    &:active {
      background: ${(props) =>
        props.theme === 'light'
          ? `rgb(${getToken('color-primary-100')})`
          : `rgb(${getToken('color-primary-60')})`};
      border-color: ${(props) =>
        props.theme === 'light'
          ? `rgb(${getToken('color-primary-100')})`
          : `rgb(${getToken('color-primary-60')})`};
      transition: all 0.2s ease-in-out;
      color: ${(props) =>
        props.theme === 'light'
          ? `rgb(${getToken('color-text-inverted')})`
          : `rgb(${getToken('color-text-inverted')})`};
    }

    &:focus {
      outline: 1px solid
        ${(props) =>
          props.theme === 'light'
            ? `rgb(${getToken('color-primary-100')})`
            : `rgb(${getToken('color-primary-60')})`};
    }
  }

  ul.inputs-list {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }

  .hs-error-msgs {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      .hs-main-font-element,
      .hs-error-msg {
        font-size: 14px;
        line-height: 18px;
        margin: 8px 0;
        color: rgb(${getToken('color-text-error')});
      }
    }
  }
`;
