import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const ColumnFeaturesWrapper = styled.section`
  display: flex;
  width: 100%;
  color: rgb(${getToken('color-primary-100')});
  background: ${({ theme }) => theme.color.neutral.lighter3};
  padding: 60px 0;

  & > div {
    display: grid;
    justify-content: space-between;
  }

  ${screen.md} {
    padding: 100px 0;
  }
  ${screen.lg} {
    padding: 120px 0;
  }
  ${screen.xxlg} {
    padding: 80px 0 160px;
  }
`;

export const TitleWrapper = styled.div`
  margin: auto 0;

  br {
    display: none;

    ${screen.md} {
      display: block;
    }
  }

  h2 {
    margin-bottom: 80px;

    ${screen.md} {
      margin-bottom: 100px;
    }

    ${screen.xxlg} {
      margin-bottom: 92px;
    }
  }
`;

export const Columns = styled.div`
  display: grid;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;

  ${screen.md} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${screen.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
