// styles
import { BannerWrapper, BannerContainer, Message } from './BannerStrip.styles';

export function BannerStrip({ content }) {
  return (
    <BannerWrapper>
      <BannerContainer>
        {content && <Message>{content}</Message>}
      </BannerContainer>
    </BannerWrapper>
  );
}
