// components
import { Container } from 'components/Grid';

//miranda button
import {
  MirandaSecondaryButton,
  MirandaHollowSecondaryButton,
} from '@/components/Button/BaseButton';

// styles
import {
  TwoColumnsCTAWrapper,
  Title,
  SubTitle,
  ContentWrapper,
} from './TwoColumnsCTA.styles';

export function TwoColumnsCTA({ content }) {
  return (
    <TwoColumnsCTAWrapper>
      <Container>
        {content?.title && <Title>{content.title}</Title>}
        {content?.subtitle && <SubTitle>{content.subtitle}</SubTitle>}
        <ContentWrapper>
          {content?.ctas &&
            content.ctas.map((item, key) => {
              if (item.variant === 'primary') {
                return (
                  <MirandaSecondaryButton
                    as="a"
                    key={key + item.text.trim()}
                    target={item.external ? `_blank` : `_self`}
                    rel={item.external ? `noreferrer noopener` : ``}
                    href={item.url}
                  >
                    <span>{item.text}</span>
                  </MirandaSecondaryButton>
                );
              }
              return (
                <MirandaHollowSecondaryButton
                  as="a"
                  key={key + item.text.trim()}
                  target={item.external ? `_blank` : `_self`}
                  rel={item.external ? `noreferrer noopener` : ``}
                  href={item.url}
                >
                  <span>{item.text}</span>
                </MirandaHollowSecondaryButton>
              );
            })}
        </ContentWrapper>
      </Container>
    </TwoColumnsCTAWrapper>
  );
}
