import styled from 'styled-components';

//components
import { Container } from '@/components/Grid';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SideFeaturesWrapper = styled.section`
  display: flex;
  width: 100%;
  color: rgb(${getToken('color-primary-100')});
  background: ${({ theme }) => theme.color.neutral.lighter3};
  padding: 20px 0 40px;

  &.bg {
    background: ${({ theme }) => theme.color.neutral.lighter3}
      url(/images/shipperguide/side-features/bg-side-left.svg) bottom left
      no-repeat;
    background-size: auto 370px;
  }

  & > div {
    display: flex;
    flex-direction: column-reverse;
  }

  ${screen.md} {
    &.bg {
      background-size: auto 570px;
    }
    padding: 20px 0 57px;
  }

  ${screen.lg} {
    &.bg {
      background-position: left center;
      background-size: auto 100%;
    }
    min-height: 696px;
    padding: 20px 0;

    & > div {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 40% auto;
    }
  }

  ${screen.xxlg} {
    &.bg {
      background-size: auto 170%;
    }
    min-height: 776px;
    & > div {
      grid-template-columns: 50% auto;
    }
  }
`;

export const MediaHolder = styled.div`
  display: grid;
  height: 100%;

  img {
    max-width: 100%;
    height: auto;
    margin: auto;

    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.05);

    ${screen.md} {
      width: 482px;
      height: 479px;
      margin: 20px auto auto auto;
    }
    ${screen.lg} {
      width: 366px;
      height: 364px;
      margin: auto 20px auto auto;
      transform: translateX(-10%);
    }

    ${screen.xxlg} {
      width: 543px;
      height: 539px;
      transform: translateX(-23%);
    }
  }
`;

export const CustomContainer = styled(Container)`
  display: grid;
  align-items: center;
`;

export const ContentHolder = styled.div`
  display: grid;
  align-items: center;
  height: fit-content;
`;

export const Columns = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;

  ${screen.md} {
    padding-top: unset;
    gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
`;
