import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SideCTAWrapper = styled.section`
  display: flex;
  width: 100%;
  overflow: hidden;
  background: rgb(${getToken('color-background-inverted')})
    url(/images/managed-transportation/mt-side-xxlg.jpg) top center no-repeat;
  background-size: contain;
  position: relative;

  ${screen.md} {
    background: rgb(${getToken('color-background-inverted')});
  }

  & > div {
    display: grid;

    ${screen.md} {
      grid-template-columns: 30% auto;
    }
  }
`;

export const ImageSpacer = styled.div`
  display: flex;
  width: 100%;
  min-height: 289px;
  position: relative;

  img {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    right: 0;
    display: none;

    ${screen.md} {
      display: block;
    }
  }

  ${screen.lg} {
    min-height: 616px;
  }
`;
export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgb(${getToken('color-background-inverted')});
  padding: 40px 0;
  position: relative;
  text-align: center;

  &::after,
  &::before {
    display: block;
    content: '';
    height: 100%;
    width: 100vw;
    background: rgb(${getToken('color-background-inverted')});
    top: 0;
    left: 100%;
    position: absolute;
  }

  &::before {
    left: unset;
    right: 100%;
  }

  ${screen.md} {
    padding: 20px 40px;
    text-align: left;

    &::before {
      display: none;
    }
  }

  ${screen.lg} {
    padding: 30px 58px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 20px 0;
  align-items: center;

  a {
    padding: 8px 16px;
  }

  ${screen.md} {
    flex-direction: row;

    a {
      width: auto;
      font-size: 12px;
      padding: 8px 16px;
    }
  }

  ${screen.lg} {
    a {
      padding: 16px 24px;
      font-size: 16px;
    }
  }
`;

export const Title = styled.div`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-inverted')});
  font-size: 26px;
  line-height: 39px;

  strong {
    color: rgb(${getToken('color-brand')});
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 60px;
  }
  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const Description = styled.div`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-inverted')});
  font-size: 12px;
  line-height: 16.8px;

  ${screen.lg} {
    font-size: 16px;
    line-height: 24px;
  }
  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
  }
`;
