import {
  StyledTable,
  TableRow,
  TableColumn,
  FirstColumnWrapper,
  SecondColumnWrapper,
  ThirdColumnWrapper,
} from './table.styles';

//assets - icons
import Navigation from '@/public/images/icons/loadsmart-collection/018-navigation.svg';
import Startup from '@/public/images/icons/loadsmart-collection/016-startup.svg';
import Money from '@/public/images/icons/loadsmart-collection/030-money.svg';
import Gear from '@/public/images/icons/loadsmart-collection/032-gear.svg';
import Monitor from '@/public/images/icons/loadsmart-collection/031-monitor.svg';

const content = [
  {
    firstColumn: { icon: <Navigation />, text: 'User Interface' },
    secondColumn: 'Simple and user friendly',
    thirdColumn: (
      <>
        Months of training and
        <br /> tech experts needed
      </>
    ),
  },
  {
    firstColumn: { icon: <Startup />, text: 'Implementation' },
    secondColumn: (
      <>
        Same day implementation
        <br /> with no startup fees
      </>
    ),
    thirdColumn: (
      <>
        6-12 months and
        <br /> expensive fees
      </>
    ),
  },
  {
    firstColumn: { icon: <Money />, text: 'Price' },
    secondColumn: (
      <>
        Low investment with
        <br /> quick ROI
      </>
    ),
    thirdColumn: (
      <>
        Expensive and excessive
        <br /> bells and whistles
      </>
    ),
  },
  {
    firstColumn: { icon: <Gear />, text: 'Upgrades' },
    secondColumn: (
      <>
        Constant free over-the-
        <br />
        cloud upgrades
      </>
    ),
    thirdColumn: (
      <>
        Additional fees and rare
        <br /> upgrades
      </>
    ),
  },
  {
    firstColumn: { icon: <Monitor />, text: 'Support' },
    secondColumn: 'Fast 24/7 support',
    thirdColumn: 'Spotty customer support',
  },
];

export function Table() {
  return (
    <>
      {content && (
        <StyledTable>
          <tbody>
            {content.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableColumn>
                    <FirstColumnWrapper>
                      {row.firstColumn.icon}
                      <p>{row.firstColumn.text}</p>
                    </FirstColumnWrapper>
                  </TableColumn>
                  <TableColumn>
                    <SecondColumnWrapper>
                      {row.secondColumn}
                    </SecondColumnWrapper>
                  </TableColumn>
                  <TableColumn>
                    <ThirdColumnWrapper>{row.thirdColumn}</ThirdColumnWrapper>
                  </TableColumn>
                </TableRow>
              );
            })}
          </tbody>
        </StyledTable>
      )}
    </>
  );
}
