/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';

//styles
import { Container } from '@/components/Grid';
import { IconClose } from '@loadsmart/icons';
import {
  TestimonialsWrapper,
  ImageSpacer,
  ContentHolder,
  SectionTag,
  QuoteText,
  Client,
  Name,
  Description,
  FooterContent,
  LogoWrapper,
  VideoLink,
  VideoWrapper,
  CloseButton,
} from './Testimonials.styles';

//vendor
import ReactPlayer from 'react-player/lazy';

export function Testimonials() {
  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <TestimonialsWrapper>
        <Container>
          <ImageSpacer role="presentation"> </ImageSpacer>
          <ContentHolder>
            <SectionTag>Customer Stories</SectionTag>
            <QuoteText>
              “We <strong>saved at least $1 million</strong> last year with
              ShipperGuide TMS, if not more than that.”
            </QuoteText>
            <Client>
              <Name>Katie Datillio</Name>
              <Description>
                Transportation Logistics Supervisor at Cabot Cheese
              </Description>
            </Client>
            <FooterContent>
              <LogoWrapper>
                <img
                  src="/images/shipperguide/partners-icons/cabot-icon.png"
                  alt="Cabot Logo"
                  width="80"
                  height="27"
                />
              </LogoWrapper>
              <VideoLink onClick={() => setVisible(true)}>
                Watch the 3 min video
              </VideoLink>
            </FooterContent>
          </ContentHolder>
        </Container>
        <VideoWrapper className={isVisible ? 'visible' : 'invisible'}>
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=DFrcT5OSVjo?rel=0"
            controls
            width="100%"
            height="100%"
            playing={isVisible}
          />
          <CloseButton
            onClick={() => {
              setVisible(false);
            }}
          >
            <IconClose />
          </CloseButton>
        </VideoWrapper>
      </TestimonialsWrapper>
    </>
  );
}
