// components
import { Container } from 'components/Grid';
import { SectionTitle } from '@/components/SectionTitle';

// icons
import ShipperGuideIcon from '@/public/images/shipperguide/comparison-table/shipperguide-logo.svg';
import TraditionalSystems from '@/public/images/shipperguide/comparison-table/traditional-systems.svg';

// styles
import {
  SectionWrapper,
  TitleWrapper,
  HeroWrapper,
  Icons,
} from './ComparisonTable.styles';
import { Table } from './containers/table';

export function ComparisonTable() {
  return (
    <>
      <SectionWrapper>
        <HeroWrapper />
        <Container>
          <TitleWrapper>
            <SectionTitle
              title={
                <>
                  More value.
                  <br className="showOnMobile" /> Better ROI.
                  <br /> All for less.
                </>
              }
              subtitle={`Pay only for what you need`}
              variant="left"
            />
          </TitleWrapper>
          <Icons>
            <ShipperGuideIcon />
            <TraditionalSystems />
          </Icons>
          <Table />
        </Container>
      </SectionWrapper>
    </>
  );
}
