import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const SectionWrapper = styled.section`
  padding: 60px 0;
  background-color: ${({ theme }) => theme.color.neutral.lighter3};
  text-align: center;
  position: relative;

  ${screen.xxlg} {
    padding: 57px 0 133px;
  }

  ${screen.lg} {
    padding: 62px 0 201px;
  }
`;

export const HeroWrapper = styled.div`
  position: absolute;
  height: 392px;
  width: 100%;

  background: ${({ theme }) => theme.color.neutral.lighter3}
    url(/images/shipperguide/comparison-table/comparison-table@2x.svg) right
    bottom;
  background-size: cover;
  overflow-x: hidden;
  bottom: 0;

  ${screen.md} {
    height: 392px;
    width: 537px;
  }

  ${screen.lg} {
    height: 591px;
    width: 1005px;
  }

  ${screen.xxlg} {
    height: 1197px;
    width: 1482px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 8px 0 48px;

  ${screen.md} {
    margin: 12px 0 48px;
  }

  ${screen.lg} {
    margin: 16px 0 48px;
  }

  ${screen.xxlg} {
    margin: 16px 0 64px;
  }
`;

export const Icons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;

  margin: 34px 0 23px;

  ${screen.md} {
    grid-template-columns: repeat(3, 1fr);
    margin: 40px 0 28px;
  }

  ${screen.lg} {
    margin: 60px 0 49px;
  }

  ${screen.xxlg} {
    margin: 74px 0 52px;
  }

  svg {
    height: 16px;

    ${screen.md} {
      grid-column: 2;
      height: 24px;
      padding: 0;
    }

    ${screen.lg} {
      padding: 0 0 0 30px;
    }

    ${screen.xxlg} {
      padding: 0 0 0 60px;
    }
  }

  svg:nth-child(2) {
    height: 22px;

    ${screen.md} {
      grid-column: 3;
      height: 32px;
      padding: 0;
    }
  }
`;
