import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const StyledTable = styled.table`
  background-color: ${({ theme }) => theme.color.neutral.lighter3};
  border-collapse: collapse;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.1));

  ${screen.md} {
    min-width: calc(768px - 6rem);
  }

  ${screen.lg} {
    min-width: calc(1024px - 4.4rem);
    tr:nth-child(3) {
      td:nth-child(2) {
        br {
          display: none;
        }
      }
    }
  }

  ${screen.xxlg} {
    margin: 0 auto;
    min-width: 1287px;
  }
`;

export const TableRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.greens.lightest};

  &:nth-child(2n) {
    background-color: rgb(${getToken('color-background-primary')});
  }

  ${screen.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const TableColumn = styled.td`
  text-align: start;

  br {
    display: none;
  }

  &:nth-child(2) {
    grid-column: 1;
  }

  &:nth-child(1) {
    padding: 17px 0 25px;
  }

  ${screen.md} {
    padding: 35px 0;

    &:nth-child(2) {
      grid-column: 2;
    }

    &:nth-child(1) {
      padding: 0;
    }

    br {
      display: inline-block;
    }
  }

  ${screen.lg} {
    padding: 37px 0;
  }

  ${screen.xxlg} {
    padding: 49px 0;
  }
`;

export const FirstColumnWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 0 0 12px;

  ${screen.md} {
    margin: 0 0 0 33px;
  }

  ${screen.lg} {
    margin: 0 0 0 52px;
  }

  ${screen.xxlg} {
    margin: 0 0 0 64px;
  }

  svg {
    width: 20px;
    height: 20px;

    ${screen.lg} {
      width: 32px;
      height: 32px;
    }
  }

  p {
    font-family: ${getToken('font-family-default')};
    color: rgb(${getToken('color-primary-100')});
    font-weight: ${getToken('font-weight-bold')};
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 0 4px;

    ${screen.md} {
      margin: 0 0 0 32px;
      font-size: 16px;
      line-height: 26px;
    }

    ${screen.lg} {
      font-size: 20px;
      line-height: 32px;
    }
  }
`;

export const SecondColumnWrapper = styled.p`
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-primary-100')});
  font-weight: ${getToken('font-weight-bold')};
  border-left: 4px solid rgb(${getToken('color-brand')});
  padding: 0 0 0 8px;
  margin: 0 0 16px 12px;
  font-size: 16px;
  line-height: 22px;
  min-height: 105px;

  ${screen.md} {
    padding: 0 0 0 10px;
    font-size: 14px;
    line-height: 20px;
    min-height: 27px;
  }

  ${screen.lg} {
    padding: 0 0 0 16px;
    font-size: 16px;
    line-height: 22px;
    min-height: 44px;
  }

  ${screen.xxlg} {
    padding: 0 0 0 20px;
  }
`;

export const ThirdColumnWrapper = styled.p`
  display: flex;
  justify-content: start;
  align-items: center;
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-secondary')});
  font-weight: ${getToken('font-weight-regular')};
  border-left: 4px solid ${({ theme }) => theme.color.warning.dark};
  padding: 0 0 0 8px;
  margin: 0 12px 16px 0;
  font-size: 16px;
  line-height: 22px;
  min-height: 105px;

  ${screen.md} {
    padding: 0 0 0 10px;
    font-size: 14px;
    line-height: 20px;
    min-height: 27px;
  }

  ${screen.lg} {
    padding: 0 0 0 16px;
    font-size: 16px;
    line-height: 22px;
    min-height: 44px;
  }

  ${screen.xxlg} {
    padding: 0 0 0 20px;
  }
`;
