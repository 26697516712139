import styled, { keyframes } from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';

const containerMoviment = keyframes`
    0%{
      transform: translate(-20%, 205%);
    }
    100%{
      transform: translate(-20%, 80%);
    }
`;

const containerMovimenteOut = keyframes`
    0%{
      transform: translate(-20%, 80%);
    }
    100%{
      transform: translate(-20%, 205%);
    }
`;

const containerMovimentMobile = keyframes`
    0%{
      transform: translate(-50%, 250%);
    }
    100%{
      transform: translate(-50%, 60%);
    }
`;

const containerMovimenteMobileOut = keyframes`
    0%{
      transform: translate(-50%, 60%);
    }
    100%{
      transform: translate(-50%, 250%);
    }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  flex-direction: column;
  bottom: 12%;
  left: 50%;
  transform: translate(-50%, 250%);
  background-color: rgb(${getToken('color-background-inverted')});
  height: auto;
  width: 295px;
  border-radius: 13px;
  padding: 18px 29px;
  transform-style: preserve-3d;

  &.visible {
    animation: 1s ${containerMovimentMobile};
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s, visibility 1s;
    animation: 1s ${containerMovimenteMobileOut};
    animation-fill-mode: forwards;
  }

  ${screen.md} {
    bottom: 17%;
    width: 70%;
    height: 144px;
  }

  ${screen.lg} {
    height: 144px;
    width: 712px;
    flex-direction: row;
    padding: 39px 20px 39px 0;
    transform: translate(-20%, 205%);
    background: url(/images/animated-banner/banner_background.svg) center;
    background-size: cover;

    &.visible {
      animation: 1s ${containerMoviment};
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s, visibility 1s;
      animation: 1s ${containerMovimenteOut};
      animation-fill-mode: forwards;
    }
  }

  ${screen.xxlg} {
    height: 192px;
    width: 812px;
  }
`;

export const PageWrapper = styled.header`
  position: absolute;
  background-color: transparent;
  z-index: 100;
`;

export const AnimatedImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 55px;
  left: 0px;
  width: 100%;
  transform: translateZ(-1px);

  ${screen.md} {
    bottom: 40px;
    left: 10%;
    width: 75%;
    transform: translateZ(-1px);
  }

  ${screen.lg} {
    position: relative;
    left: 0px;
    width: 300px;
    height: 300px;
    transform: translate(-20px, -38px);
    object-fit: cover;
    object-position: 35% 0;
  }

  ${screen.xxlg} {
    width: 400px;
    height: 400px;
    transform: translate(-36px, -16%);
  }
`;

export const BannerContent = styled.div`
  color: rgb(${getToken('color-text-inverted')});
  font-family: ${getToken('platform-font-title')};
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  min-width: 100%;

  a {
    color: green;
    background: linear-gradient(
      45.01deg,
      rgb(${getToken('color-brand-accent-1')}) 23.29%,
      rgb(${getToken('color-brand-accent-2')}) 88.12%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-right: 5px;

    &:hover {
      opacity: 0.7;
    }
  }

  ${screen.md} {
    font-size: 29px;
    line-height: 37.7px;
    min-width: 380px;
  }
  ${screen.lg} {
    text-align: start;
  }
`;

export const CloseButton = styled.span`
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 10px;

  border-radius: 50%;
  border: none;
  background-color: rgb(${getToken('color-neutral-40')});
  transition: all 0.1s ease-in-out;

  top: 0px;
  right: 0px;
  transform: translate(30%, -40%);

  &:hover {
    background-color: rgb(${getToken('color-neutral-20')});
  }

  svg,
  path {
    width: 16px;
    height: 16px;

    color: rgb(${getToken('color-text-primary')});
  }

  ${screen.md} {
    transform: translate(30%, -40%);
  }

  ${screen.lg} {
    bottom: 0px;
    left: 0px;
    position: relative;
    transform: translate(10px, -200%);
  }

  ${screen.xxlg} {
    transform: translate(10px, -260%);
  }
`;
