// components
import { HubspotForm } from '@/containers-m/HubspotForm';
import { HubspotFormDescription } from '@/containers-m/HubspotForm/HubspotForm.styles';
import { shipperGuideOptions } from '@/vendor/HubspotFormIntegration';

//assets
import ShipperGuideLogo from '@/public/images/logos/shipperguide-logo.svg';
import Target from '@/public/images/icons/loadsmart-collection/038-target.svg';

// styles
import {
  FormBackground,
  ContainerLogo,
  TopLogoWrapper,
  ContainerForm,
  ContentHolder,
  Title,
  SubTitle,
  List,
  ListItem,
} from './Form.styles';

export function Form() {
  return (
    <>
      <FormBackground id="hubspot-form">
        <ContainerLogo>
          <TopLogoWrapper>
            <ShipperGuideLogo alt="ShipperGuide TMS" />
          </TopLogoWrapper>
          &nbsp;TMS
        </ContainerLogo>
        <ContainerForm>
          <ContentHolder>
            <Title>Talk to an expert and schedule your demo.</Title>
            <SubTitle>Measurable impact from day one.</SubTitle>
            <List>
              <ListItem>
                <Target />
                <p>
                  <strong>35% of savings</strong> per lane with live
                  <br /> market benchmarking
                </p>
              </ListItem>
              <ListItem>
                <Target />
                <p>
                  <strong>83% reduction in time</strong> on rate
                  <br /> procurement activities
                </p>
              </ListItem>
              <ListItem>
                <Target />
                <p>
                  Onboard your entire team in
                  <br />
                  <strong>60 minutes</strong>
                </p>
              </ListItem>
              <ListItem>
                <Target />
                <p>
                  <strong>Easy to use</strong> for even the least
                  <br /> tech-savvy people on your team
                </p>
              </ListItem>
            </List>
          </ContentHolder>
          <HubspotForm
            {...shipperGuideOptions}
            theme="white"
            variant="smaller"
            disclaimer={true}
          >
            <HubspotFormDescription>
              To begin, tell us a bit about yourself.
            </HubspotFormDescription>
          </HubspotForm>
        </ContainerForm>
      </FormBackground>
    </>
  );
}
