//styles
import {
  ContentPillWrapper,
  ContentPillIcon,
  ContentPillTitle,
  ContentPillDescription,
  ContentPillLink,
  ContentPillList,
  ContentPillItem,
} from './ContentPill.styles';

//assets
import ArrowLeft from '@/public/images/icons/loadsmart-collection/009-sg-arrow-right.svg';

export function ContentPill({ content }) {
  return (
    content && (
      <ContentPillWrapper>
        {content.icon && <ContentPillIcon>{content.icon}</ContentPillIcon>}
        {content.title && <ContentPillTitle>{content.title}</ContentPillTitle>}
        {content.description && (
          <ContentPillDescription>{content.description}</ContentPillDescription>
        )}
        {content.link && (
          <ContentPillLink
            href={content.link.url}
            {...(content.link?.external && { target: '_blank' })}
            {...(content.link?.external && {
              rel: 'noreferrer noopener',
            })}
          >
            <span> {content.link.label}</span>
          </ContentPillLink>
        )}
        {content.items && (
          <ContentPillList>
            {content.items.map((item, key) => {
              return (
                <ContentPillItem key={key + item.title.trim()}>
                  <ArrowLeft width="16" height="16" />
                  <span>{item.title}</span>
                </ContentPillItem>
              );
            })}
          </ContentPillList>
        )}
      </ContentPillWrapper>
    )
  );
}
