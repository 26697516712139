//carousel package
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

//containers
import { Paragraph2, Subtitle } from '@/components/Text';
import {
  BackgroundSection,
  TitleWrapper,
  SectionTitle,
  Carousel as CarouselWrapper,
  Slide,
  SlideButtonLeft,
  SlideButtonRight,
  FooterWrapper,
} from './CarouselSlider.styles';

// icons
import { IconArrowsExpandSide } from '@loadsmart/icons';
import { Container } from '@/components/Grid';

export const CarouselSlider = ({ content, title, footer, arrows = true }) => {
  return (
    <BackgroundSection>
      <Container>
        {title && (
          <TitleWrapper>
            <SectionTitle>{title}</SectionTitle>
          </TitleWrapper>
        )}
      </Container>

      <CarouselWrapper>
        <Carousel
          arrows={arrows}
          draggable
          swipeable
          autoPlay={true}
          autoPlaySpeed={6000}
          centerMode
          infinite
          pauseOnHover
          showDots={false}
          slidesToSlide={1}
          focusOnSelect={false}
          minimumTouchDrag={80}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 20,
            },
            tablet: {
              breakpoint: {
                max: 1023,
                min: 768,
              },
              items: 2,
              partialVisibilityGutter: 20,
            },
            mobile: {
              breakpoint: { max: 767, min: 0 },
              items: 1,
              partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
            },
          }}
          customLeftArrow={
            <SlideButtonLeft>
              <IconArrowsExpandSide />
            </SlideButtonLeft>
          }
          customRightArrow={
            <SlideButtonRight>
              <IconArrowsExpandSide />
            </SlideButtonRight>
          }
        >
          {content.length !== 0 &&
            content.map((item, index) => {
              return (
                <Slide key={'slide-team-' + item.title.trim() + '-' + index}>
                  <Subtitle>{item.title}</Subtitle>
                  <Paragraph2>{item.description}</Paragraph2>
                </Slide>
              );
            })}
        </Carousel>
      </CarouselWrapper>

      <Container>
        {footer && (
          <FooterWrapper>
            <Paragraph2>{footer}</Paragraph2>
          </FooterWrapper>
        )}
      </Container>
    </BackgroundSection>
  );
};
