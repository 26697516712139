import styled from 'styled-components';

//components
import { Container } from '@/components/Grid';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const TabsContentWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.color.neutral.lighter3};
  padding: 0;

  ${screen.xxlg} {
    padding: 40px 0 0;
  }
`;

export const TitleWrapper = styled.div`
  margin: auto;

  ${screen.lg} {
    max-width: 758px;
    margin-bottom: 50px;
  }

  ${screen.xxlg} {
    max-width: 1157px;
    margin-bottom: 40px;
  }
`;

// // // //

export const TabSectionWrapper = styled.div`
  display: grid;
  width: 100%;
`;

export const TabsNavigation = styled.div`
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-image: linear-gradient(to right, #00d7d7 23.29%, #84f11e 88.12%) 1;
  height: 102px;

  ${screen.md} {
    height: auto;
  }

  ${screen.lg} {
    height: auto;
  }
  ${screen.xxlg} {
    height: 103px;
  }
`;

export const NavigationList = styled.nav`
  display: grid;
  grid-template-columns: repeat(${(props) => props.items}, auto);
  min-width: 100%;
  width: calc(${(props) => props.items} * 88px);

  position: absolute;
  top: 0;
  left: 0;
  margin: auto;

  transition: all 0.1s ease-in-out;

  ${screen.md} {
    min-width: unset;
    position: relative;
    height: auto;
    top: unset;
    left: unset;
    width: calc(${(props) => props.items} * 140px);
  }

  ${screen.xxlg} {
    width: calc(${(props) => props.items} * 167px);
  }
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  background: transparent;
  cursor: pointer;

  width: 80px;
  height: 100px;

  &::after {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    position: absolute;
    top: calc(100% - 4px);
    left: 0;
    opacity: 0;
    background: rgb(${getToken('color-primary-100')});

    z-index: 2;
  }

  &.active,
  &:hover {
    &::after {
      opacity: 1;
      top: calc(100% - 3px);

      transition: all 0.15s ease-in-out;
    }
  }

  ${screen.md} {
    width: 140px;
  }

  ${screen.xxlg} {
    width: 167px;
    height: 100px;
  }
`;

export const NavItemIcon = styled.span`
  width: 24px;
  height: 24px;

  svg {
    max-width: 100%;
    height: auto;
  }

  ${screen.md} {
    width: 32px;
    height: 32px;
  }
`;

export const NavItemLabel = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-100')});
  display: flex;
  text-align: center;
  font-size: 14px;
  line-height: 22.4px;
  margin: 6px 0;

  ${screen.md} {
    font-size: 18px;
    line-height: 28.8px;
  }
`;

// // //

export const TabsSlider = styled.div`
  background: linear-gradient(180deg, #ffffff 0%, #eefaf5 100%);
  height: 100%;
`;

export const TabSliderBackground = styled.div`
  padding: 20px 0 40px;

  &.bg {
    background: url(/images/shipperguide/tabs/background-tabs.svg) no-repeat
      right bottom;
    background-size: 60%;
  }

  ${screen.md} {
    &.bg {
      background-size: unset;
    }
    padding: 50px 0;
  }

  ${screen.lg} {
    padding: 80px 0 60px;
  }

  ${screen.xxlg} {
    padding: 90px 0 80px;
  }
`;

// // //

export const TabList = styled(Container)``;

export const Tab = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;

  visibility: 0;
  opacity: 0;
  poniter-events: none;
  z-index: 0;
  position: absolute;

  &.active {
    position: relative;
    visibility: 1;
    opacity: 1;
    poniter-events: default;
    z-index: 5;
    transition: opacity 0.5s ease-in-out;
  }

  ${screen.lg} {
    display: grid;
    gap: 40px;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
  }
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  min-height: 605px;

  ${screen.md} {
    min-height: 580px;
  }

  ${screen.lg} {
    width: 418px;
    min-height: 740px;
  }

  ${screen.xxlg} {
    width: 521px;
    min-height: 720px;
  }
`;

export const ContentTitle = styled.h3`
  display: inline-flex;
  align-items: center;
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-100')});
  font-size: 24px;
  line-height: 38.4px;
  margin: 4px 0;

  ${screen.md} {
    margin: 1rem 0;
  }

  span {
    margin: auto 4px;
  }
`;

export const ContentDescription = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-60')});
  font-size: 16px;
  line-height: 25.6px;
`;

export const TagsSoon = styled.span`
  align-self: flex-start;
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 10px;
  line-height: 10px;
  text-transform: uppercase;
  color: rgb(${getToken('color-text-inverted')});
  border: none;
  display: inline-flex;
  max-width: 100px;
  height: auto;
  padding: 4px 8px;
  margin: auto;
  border-radius: 100px;
  background: rgb(${getToken('color-primary-60')});
`;

// -

export const SubitemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 20px 0 40px;

  ${screen.md} {
    grid-template-columns: 1fr 1fr;
    gap: 22px;
    margin: 30px 0 20px;
  }

  ${screen.lg} {
    gap: 32px;
  }
  ${screen.xxlg} {
    margin: 50px 0 20px;
  }
`;

export const MediaHolder = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  img,
  svg {
    max-width: 100%;
    height: auto;
  }

  ${screen.xxlg} {
    align-items: flex-start;
  }
`;

export const ArrowsHolder = styled.div`
  width: 100%;
  display: block;
  padding: 20px 0 60px;

  ${screen.md} {
    padding: 0 0 40px;
  }
  ${screen.lg} {
    padding: 0 0 85px;
  }
  ${screen.xxlg} {
    padding: 40px 0;
  }
`;

export const TabPrev = styled.div`
  display: flex;
  height: 30px;
  max-width: 50%;
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-60')});
  font-size: 18px;
  line-height: 28.8px;
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  justify-self: flex-start;
  float: left;
  padding-left: 22px;

  &::before {
    content: ' \\2190 ';
    display: block;
    width: 22px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;

    color: rgb(${getToken('color-brand')});
  }
`;

export const TabNext = styled(TabPrev)`
  padding-left: unset;
  padding-right: 22px;
  justify-self: flex-end;
  float: right;
  &::before {
    content: ' \\2192 ';
    left: auto;
    right: 0;

    text-align: right;
  }
`;
