//assets
import {
  IconOpportunity,
  IconGameChangers,
  IconFunding,
} from '@loadsmart/icons';

import { FeatureGroup, responsiveIconStyle } from '@/components/Feature';
import { Container } from '@/components/Grid/Container';

import {
  Featured,
  ReasonListTitle,
  FeatureHighlight,
  FeaturedTitle,
  FeaturedDescription,
  FeaturedIcon,
  FeaturedSection,
} from './WeInNumbers.styles';

export const WeInNumbers = () => (
  <FeaturedSection>
    <Container>
      <ReasonListTitle>
        Here’s why you
        <br /> should care
      </ReasonListTitle>
      <FeatureGroup>
        <Featured>
          <FeaturedIcon>
            <IconGameChangers role="img" css={responsiveIconStyle} />
          </FeaturedIcon>
          <FeatureHighlight>400+</FeatureHighlight>
          <FeaturedTitle>
            GAME
            <br /> CHANGERS
          </FeaturedTitle>
          <FeaturedDescription>
            Our diverse and global team is a rapidly growing group who
            collaborate to create cutting edge, user-friendly technology
            services in the logistics industry and we have fun while doing it.
          </FeaturedDescription>
        </Featured>
        <Featured>
          <FeaturedIcon>
            <IconOpportunity role="img" css={responsiveIconStyle} />
          </FeaturedIcon>
          <FeatureHighlight>$900B+</FeatureHighlight>
          <FeaturedTitle>INDUSTRY OPPORTUNITY</FeaturedTitle>
          <FeaturedDescription>
            It’s not often you’re faced with a $900B+ industry that hasn’t
            embraced technology. With this type of market cap, disruption is
            inevitable, and we’re leading the way.
          </FeaturedDescription>
        </Featured>
        <Featured>
          <FeaturedIcon>
            <IconFunding role="img" css={responsiveIconStyle} />
          </FeaturedIcon>
          <FeatureHighlight>$200M</FeatureHighlight>
          <FeaturedTitle>
            SERIES ‘D’
            <br /> FUNDING
          </FeaturedTitle>
          <FeaturedDescription>
            We’ve raised $346.4M to date from some of the industry’s biggest
            names and strategic partners, including BlackRock, SoftBank Latin
            America Fund , TFI International, Maersk, Ports America, and CSX
            Corp.
          </FeaturedDescription>
        </Featured>
      </FeatureGroup>
    </Container>
  </FeaturedSection>
);
