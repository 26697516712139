import { useEffect } from 'react';

// embed form
export const setHubspotFormIntegration = (options) => {
  const script = document.createElement('script');

  script.src = 'https://js.hsforms.net/forms/v2.js';
  script.onload = () => {
    if (window.hbspt) {
      window.hbspt.forms.create(options);
    }
  };

  document.body.appendChild(script);
};

export const HubspotFormRaw = ({
  portalId,
  formId,
  sfdcCampaignId,
  target = 'integration-hubspot',
}) => {
  useEffect(() => {
    setHubspotFormIntegration({
      portalId,
      formId,
      sfdcCampaignId,
      target: `#${target}`,
    });
  }, [formId, portalId, sfdcCampaignId, target]);

  return <div id={target} />;
};
