import styled, { css } from 'styled-components';
import { screen } from '@/helpers/screen';

//context component
import { UpperButton, BaseButton, GhostButton } from './BaseButton';

export const Button = styled(UpperButton)`
  margin: 16px 0;

  ${screen.md} {
    width: 200px;
  }

  ${screen.lg} {
    width: 300px;
  }
`;

export const heroButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
  width: 100%;
`;

const responsiveSize = css`
  ${screen.md} {
    width: 158px;
  }

  ${screen.lg} {
    width: 227px;
  }

  ${screen.xxlg} {
    width: 300px;
  }
`;

export const ButtonHero = styled(BaseButton)`
  ${heroButtonStyle};
  ${responsiveSize};
`;

export const GhostButtonHero = styled(GhostButton)`
  ${heroButtonStyle};
  ${responsiveSize};
`;
