import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import {
  Feature,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
} from '@/components/Feature';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const FeaturedSection = styled.section`
  background: rgb(${getToken('color-background-secondary')});
  padding: 50px 0;

  ${screen.md} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const Featured = styled(Feature)`
  ${screen.md} {
    margin: 0 8px 0;
  }

  ${screen.xxlg} {
    margin: 0 10px 0;
  }
`;

export const ReasonListTitle = styled(FeatureTitle)`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  text-align: center;
  text-transform: none;
  font-size: 26px;
  line-height: 39px;
  margin: 0 0 42px;

  ${screen.md} {
    margin: 0 0 50px;

    br {
      display: none;
    }
  }

  ${screen.xxlg} {
    margin: 0 0 35px;
  }
`;

export const FeatureHighlight = styled.h3`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});

  margin: 10px 0;

  font-weight: bold;
  font-size: 38px;
  line-height: 47.5px;
  text-align: center;
  text-transform: uppercase;

  ${screen.lg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const FeaturedTitle = styled(FeatureTitle)`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  margin: 0 0 10px;
  font-size: 20px;
  line-height: 30px;

  ${screen.lg} {
    font-size: 26px;
    line-height: 39px;
  }

  ${screen.xxlg} {
    br {
      display: none;
    }
  }
`;

export const FeaturedDescription = styled(FeatureDescription)`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-primary')});

  margin: 0;
  font-size: 14px;
  line-height: 21px;

  ${screen.md} {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const FeaturedIcon = styled(FeatureIcon)`
  svg {
    width: 85px;
    height: 85px;
    color: rgb(${getToken('color-primary-60')});
  }
`;
