import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const TestimonialsWrapper = styled.section`
  display: flex;
  width: 100%;
  overflow: hidden;
  background: rgb(${getToken('color-primary-100')})
    url(/images/shipperguide/testimonials/cover-cabot@2x.jpg) top center
    no-repeat;
  background-size: contain;
  position: relative;

  ${screen.md} {
    background: rgb(${getToken('color-primary-100')});
  }

  & > div {
    display: grid;

    ${screen.md} {
      grid-template-columns: 30% auto;
    }
  }
`;

export const ImageSpacer = styled.div`
  display: flex;
  width: 100%;
  min-height: 165px;
  position: relative;

  ${screen.lg} {
    min-height: 616px;
  }

  &::after {
    display: none;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;

    ${screen.md} {
      display: block;
      background: rgb(${getToken('color-primary-100')})
        url(/images/shipperguide/testimonials/cover-cabot@2x.jpg) center
        no-repeat;
      background-size: auto 100%;
      width: 50vw;
    }
    ${screen.lg} {
      width: 100vw;
      background: rgb(${getToken('color-primary-100')})
        url(/images/shipperguide/testimonials/cover-cabot@2x.jpg) 140% center
        no-repeat;
      background-size: auto 100%;
    }
    ${screen.xxlg} {
      width: 100vw;
      background: rgb(${getToken('color-primary-100')})
        url(/images/shipperguide/testimonials/cover-cabot@2x.jpg) 135% center
        no-repeat;

      background-size: auto 100%;
    }
  }
`;
export const ContentHolder = styled.div`
  background: rgb(${getToken('color-primary-100')});
  padding: 0;
  position: relative;

  &::after,
  &::before {
    display: block;
    content: '';
    height: 100%;
    width: 100vw;
    background: rgb(${getToken('color-primary-100')});
    top: 0;
    left: 100%;
    position: absolute;
  }

  &::before {
    left: unset;
    right: 100%;
  }

  ${screen.md} {
    padding: 20px 40px;

    &::before {
      display: none;
    }
  }

  ${screen.lg} {
    padding: 30px 60px;
  }

  ${screen.xxlg} {
    padding: 30px 80px;
  }
`;
export const SectionTag = styled.div`
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-20')});
  font-size: 20px;
  line-height: 32px;
  padding: 30px 0 0px;

  ${screen.xxlg} {
    padding: 30px 0;
  }
`;
export const QuoteText = styled.div`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-inverted')});
  font-size: 38px;
  line-height: 43.7pxpx;

  padding: 30px 0 20px;

  strong {
    color: rgb(${getToken('color-brand')});
  }


  ${screen.lg} {
    font-size: 48px;
    line-height: 55.2px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 66.7px;
  }
`;
export const Client = styled.div`
  margin-bottom: 30px;

  ${screen.md} {
    margin-bottom: 80px;
  }
`;
export const Name = styled.span`
  display: flex;
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-text-inverted')});
  font-size: 16px;
  line-height: 25.6px;
`;
export const Description = styled.span`
  display: flex;
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-20')});
  font-size: 14px;
  line-height: 19.6px;
`;
export const FooterContent = styled.div`
  margin: 20px 0;
  display: flex;
`;
export const LogoWrapper = styled.div`
  width: 80px;
  height: auto;
  margin-right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const VideoLink = styled.span`
  display: inline-flex;
  padding-right: 30px;
  position: relative;
  cursor: pointer;

  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-inverted')});
  font-size: 16px;
  line-height: 25.6px;
  text-decoration: underline;

  &::after {
    content: ' \\2192 ';
    display: block;
    width: 20px;
    height: auto;

    position: absolute;
    top: 0;
    right: 0;
    color: rgb(${getToken('color-brand')});
    font-size: 14px;
  }
`;

export const VideoWrapper = styled.div`
  display: block !important;
  position: absolute;
  background: rgb(${getToken('color-primary-100')});
  box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  width: 100%;
  height: calc(100% - 165px);
  top: auto;
  bottom: 0;
  z-index: 10;

  .react-player {
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }

  &.invisible {
    transition: left 1s;
    left: calc(100% + 60px);
  }

  &.visible {
    transition: left 1s;
    right: auto;
    left: 0;
  }

  ${screen.md} {
    width: 50%;
    height: 100%;
    bottom: auto;

    &.invisible {
      left: calc(100% + 60px);
      top: 0;
    }

    &.visible {
      left: 50%;
      top: 0;
    }
  }
`;

export const CloseButton = styled.span`
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: rgb(${getToken('color-text-inverted')});
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;

  box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.2);

  svg,
  path {
    max-width: 100%;
    height: auto;
    color: rgb(${getToken('color-primary-100')});
  }

  ${screen.md} {
    left: -30px;
    width: 40px;
    height: 40px;
  }
  ${screen.lg} {
    width: 60px;
    height: 60px;
  }
  ${screen.xxlg} {
    width: 60px;
    height: 60px;
  }
`;
