//components
import { Container } from '@/components/Grid';
import { SectionTitle } from '@/components/SectionTitle';
import { ContentPill } from '@/components/ContentPill';

//styles
import {
  SideContentWrapper,
  TitleWrapper,
  CTAs,
  StyledButton,
  Columns,
} from './SideContent.styles';

export function SideContent({ content }) {
  return (
    <>
      <SideContentWrapper>
        <Container>
          {content.title && content.description && (
            <TitleWrapper>
              <SectionTitle
                title={content.title}
                description={content.description}
                variant="left"
              />
              {content.cta && (
                <CTAs>
                  <StyledButton as="a" href={content.cta.href}>
                    {content.cta.label}
                  </StyledButton>
                </CTAs>
              )}
            </TitleWrapper>
          )}
          <Columns>
            {content.items.length > 0 &&
              content.items.map((item, key) => {
                return (
                  <ContentPill
                    key={item.title.trim() + key}
                    content={{
                      icon: item.icon,
                      title: item.title,
                      description: item.description,
                    }}
                  />
                );
              })}
          </Columns>
        </Container>
      </SideContentWrapper>
    </>
  );
}
