import styled from 'styled-components';

export const CustomersLogosWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;

  background: linear-gradient(180deg, #ffffff 50%, #eefaf5 100%);
`;

export const LogosList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.a`
  max-width: 100%;
  height: auto;
  margin: 28px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const LogoCapterra = styled(Logo)`
  img {
    width: 230px;
  }
`;

export const LogoG2 = styled(Logo)`
  img {
    width: 180px;
  }
`;
