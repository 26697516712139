/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';

//slider
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

//components
import { Container } from '@/components/Grid';
import { HubspotFormRaw } from '../HubspotFormRaw';

// embed form
import { shipperGuideROICalcOptions } from '@/vendor/HubspotFormIntegration';

//styles
import {
  WideCalculatorWrapper,
  ContentHolder,
  SectionHeader,
  TitleCustom,
  ParagraphCustom,
  SubtitleCustom,
  Paragraph2Custom,
  Paragraph3Custom,
  CalculatorWrapper,
  SliderContainer,
  TableWrapper,
  TableContainer,
  FormWrapper,
  FormHeader,
  FormTitle,
  SliderWrapper,
  //
  LabelAmount,
  LabelTitle,
  LabelEstimated,
  LabelSaving,
  ArrowWrapper,
  InfoWrapper,
} from './WideCalculatorShipperGuide.styles';

//assets
import Tag from '@/public/images/icons/loadsmart-collection-2/icon-Tag.svg';
import Info from '@/public/images/icons/icon-info.svg';

const baseData = {
  companyFreightSpend: 0,
  procurementAnalytics: {
    moreContract: {
      percent: 5,
      total: 0,
    },
    moreSpot: {
      percent: 12.5,
      total: 0,
    },
  },
  planningOptimization: {
    moreMultimodal: {
      percent: 7.5,
      total: 0,
    },
    singleMode: {
      percent: 15,
      total: 0,
    },
  },
  executionAutomation: {
    singleEmployee: {
      percent: 1.5,
      total: 0,
    },
    multipleEmployees: {
      percent: 5,
      total: 0,
    },
  },
  cumulativeTransSavingPotential: {
    initial: {
      percent: 0,
      total: 0,
    },
    final: {
      percent: 0,
      total: 0,
    },
  },
};

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const shortFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumSignificantDigits: 3,
});

const calcField = [];

export function WideCalculatorShipperGuide({}) {
  const [userData, setUserData] = useState(baseData);
  const [baseValue, setBaseValue] = useState(50000000);
  const [load, setLoad] = useState(false);

  function watchSubmit() {
    document.addEventListener('submit', function (event) {
      setLoad(false);
    });
  }

  function setupFields() {
    window.addEventListener('message', (event) => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormReady' &&
        event.data.id === shipperGuideROICalcOptions.formId
      ) {
        calcField['companyFreightSpend'] = document
          .getElementById('hsForm_' + shipperGuideROICalcOptions.formId)
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___1"]'
          );

        calcField['procurementAnalytics'] = document
          .getElementById('hsForm_' + shipperGuideROICalcOptions.formId)
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___2"]'
          );

        calcField['planningOptimization'] = document
          .getElementById('hsForm_' + shipperGuideROICalcOptions.formId)
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___3"]'
          );

        calcField['executionAutomation'] = document
          .getElementById('hsForm_' + shipperGuideROICalcOptions.formId)
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___4"]'
          );
        calcField['cumulativeTransSavingPotential'] = document
          .getElementById('hsForm_' + shipperGuideROICalcOptions.formId)
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___5"]'
          );
      }

      if (!load) {
        roiCalculator(baseValue);
        setLoad(true);
      }
    });
  }

  function roiCalculator(base) {
    let dataCopy = {
      companyFreightSpend: base,
      procurementAnalytics: {
        moreContract: {
          percent: 5,
          total: (base / 100) * 5,
        },
        moreSpot: {
          percent: 12.5,
          total: (base / 100) * 12.5,
        },
      },
      planningOptimization: {
        moreMultimodal: {
          percent: 7.5,
          total: (base / 100) * 7.5,
        },
        singleMode: {
          percent: 15,
          total: (base / 100) * 15,
        },
      },
      executionAutomation: {
        singleEmployee: {
          percent: 1.5,
          total: (base / 100) * 1.5,
        },
        multipleEmployees: {
          percent: 5,
          total: (base / 100) * 5,
        },
      },
      cumulativeTransSavingPotential: {
        initial: {
          percent: 0,
          total: 0,
        },
        final: {
          percent: 0,
          total: 0,
        },
      },
    };

    const hadleSumUp = {
      initial: {
        percent:
          ((dataCopy.procurementAnalytics.moreContract.total +
            dataCopy.planningOptimization.moreMultimodal.total) /
            2 +
            dataCopy.executionAutomation.singleEmployee.total) /
          base,
        total:
          (dataCopy.procurementAnalytics.moreContract.total +
            dataCopy.planningOptimization.moreMultimodal.total) /
            2 +
          dataCopy.executionAutomation.singleEmployee.total,
      },
      final: {
        percent:
          ((dataCopy.procurementAnalytics.moreSpot.total +
            dataCopy.planningOptimization.singleMode.total) /
            2 +
            dataCopy.executionAutomation.multipleEmployees.total) /
          base,
        total:
          (dataCopy.procurementAnalytics.moreSpot.total +
            dataCopy.planningOptimization.singleMode.total) /
            2 +
          dataCopy.executionAutomation.multipleEmployees.total,
      },
    };

    dataCopy = { ...dataCopy, cumulativeTransSavingPotential: hadleSumUp };
    setUserData(dataCopy);

    if (calcField['companyFreightSpend']) {
      // fill hub hidden inputs
      calcField['companyFreightSpend'].value = formatter.format(
        dataCopy.companyFreightSpend
      );
      calcField['procurementAnalytics'].value =
        formatter.format(dataCopy.procurementAnalytics.moreContract.total) +
        ' to ' +
        formatter.format(dataCopy.procurementAnalytics.moreSpot.total);
      calcField['planningOptimization'].value =
        formatter.format(dataCopy.planningOptimization.moreMultimodal.total) +
        ' to ' +
        formatter.format(dataCopy.planningOptimization.singleMode.total);
      calcField['executionAutomation'].value =
        formatter.format(dataCopy.executionAutomation.singleEmployee.total) +
        ' to ' +
        formatter.format(dataCopy.executionAutomation.multipleEmployees.total);
      calcField['cumulativeTransSavingPotential'].value =
        formatter.format(
          dataCopy.cumulativeTransSavingPotential.initial.total
        ) +
        ' to  ' +
        formatter.format(dataCopy.cumulativeTransSavingPotential.final.total);
    }
  }

  useEffect(() => {
    if (!load) {
      setupFields();
      watchSubmit();
    }
  });

  useEffect(() => {
    roiCalculator(baseValue);
  }, [baseValue]);

  return (
    <>
      <WideCalculatorWrapper>
        <Container id="roi-calculator">
          <ContentHolder>
            <SectionHeader>
              <TitleCustom>
                Use this calculator to see how much ShipperGuide TMS could save
                your company!
              </TitleCustom>

              <ParagraphCustom>
                We’ve gathered data from real organizations currently leveraging
                ShipperGuide TMS, as well as publicly available data, to develop
                a calculator that helps you predict how much your company could
                be saving.
              </ParagraphCustom>
            </SectionHeader>

            <CalculatorWrapper>
              <SliderContainer>
                <SubtitleCustom>
                  Enter in Your Company’s Annual Freight Spend:
                </SubtitleCustom>
                <Paragraph2Custom>
                  Use the scroll bar to enter your company’s annual freight
                  spend and see your potential savings.
                </Paragraph2Custom>
                <LabelAmount>${shortFormatter.format(baseValue)}</LabelAmount>

                <SliderWrapper>
                  {load && (
                    <Slider
                      min={1000000}
                      max={200000000}
                      step={1000000}
                      value={baseValue}
                      tooltip={false}
                      onChange={(value) => {
                        setBaseValue(value);
                      }}
                    />
                  )}
                </SliderWrapper>
              </SliderContainer>

              <TableWrapper>
                {/* results */}
                <FormTitle>Estimated Savings Opportunity</FormTitle>
                <TableContainer>
                  <dl>
                    <dt>
                      <ArrowWrapper>
                        <Tag width="16" heigh="16" />
                      </ArrowWrapper>
                      <LabelTitle>Procurement & Analytics |</LabelTitle> &nbsp;
                      Contract to Spot{' '}
                      <InfoWrapper>
                        <Info width="16" height="16" />
                        <div className="popup-info">
                          ShipperGuide allows you to leverage internal and
                          external rate benchmarks, automatically identifying
                          and notifying you of lanes where you have room for
                          improvment. Our easy to use Procurement module makes
                          it simple for you to run RFPs more often and get the
                          best prices for each lane. Your tender was rejected?
                          No worries, we are integrated with multiple instant
                          rate providers for you to find the best recovery
                          option in the spot market.{' '}
                          <a
                            href="https://lp.loadsmart.com/hubfs/5041527/121721%20Cabot%20Cheese%20Testimonial%20EP.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            [Cabot Cheese case study, “over $1m in savings”]
                          </a>
                        </div>
                      </InfoWrapper>
                    </dt>
                    <dd>
                      <LabelEstimated>
                        {formatter.format(
                          userData.procurementAnalytics.moreContract.total
                        )}{' '}
                        to{' '}
                        {formatter.format(
                          userData.procurementAnalytics.moreSpot.total
                        )}
                      </LabelEstimated>
                    </dd>
                    <dt>
                      <ArrowWrapper>
                        <Tag width="16" heigh="16" />
                      </ArrowWrapper>
                      <LabelTitle>Planning Optimization |</LabelTitle> &nbsp;
                      Multimodal to Single Mode{' '}
                      <InfoWrapper>
                        <Info width="16" height="16" />
                        <div className="popup-info">
                          The multimodal capabilities of ShipperGuide ensure you
                          are finding the best transportation mode for a given
                          load. Our algorightms and logistics experts help you
                          save money by optimizing your allocation across TL,
                          PTL, vLTL and LTL. Furthermore, ShipperGuide allows
                          you to consolidate multiple orders into a single
                          shipment, uncovering more cost saving opportunities in
                          your network.{' '}
                          <a
                            href="https://blog.loadsmart.com/case-study-general-insulation-company"
                            target="_blank"
                            rel="noreferrer"
                          >
                            [General Insulation case study, “18% in LTL
                            savings”]
                          </a>
                        </div>
                      </InfoWrapper>
                    </dt>
                    <dd>
                      <LabelEstimated>
                        {formatter.format(
                          userData.planningOptimization.moreMultimodal.total
                        )}{' '}
                        to{' '}
                        {formatter.format(
                          userData.planningOptimization.singleMode.total
                        )}
                      </LabelEstimated>
                    </dd>
                    <dt>
                      <ArrowWrapper>
                        <Tag width="16" heigh="16" />
                      </ArrowWrapper>
                      <LabelTitle>Execution and Automation |</LabelTitle> &nbsp;
                      Small to Large Teams{' '}
                      <InfoWrapper>
                        <Info width="16" height="16" />
                        <div className="popup-info">
                          ShipperGuide automates the load execution from as much
                          as possible, leaving you to manage only the
                          exceptions. From ERP/OMS integration, through a rules
                          based tender automation system, integrated
                          direct-to-carrier tendering and a seamless integration
                          with our leading dock scheduling platform, Opendock,
                          we got you covered! If any exceptions arise, our
                          Control Tower will promptly notify you and facilitate
                          action.{' '}
                          <a
                            href="https://blog.loadsmart.com/case-study-red-gold"
                            target="_blank"
                            rel="noreferrer"
                          >
                            [Red Gold case study, “25% operational efficiency
                            increase”]
                          </a>
                        </div>
                      </InfoWrapper>
                    </dt>
                    <dd>
                      <LabelEstimated>
                        {formatter.format(
                          userData.executionAutomation.singleEmployee.total
                        )}{' '}
                        to{' '}
                        {formatter.format(
                          userData.executionAutomation.multipleEmployees.total
                        )}
                      </LabelEstimated>
                    </dd>
                    <dt className="separator">
                      <ArrowWrapper className="green">
                        <Tag width="16" heigh="16" />
                      </ArrowWrapper>
                      <LabelTitle>
                        Cumulative Transportation Savings potential |
                      </LabelTitle>{' '}
                      &nbsp; Savings Potential
                    </dt>
                    <dd>
                      <LabelSaving>
                        {formatter.format(
                          userData.cumulativeTransSavingPotential.initial.total
                        )}{' '}
                        to{' '}
                        {formatter.format(
                          userData.cumulativeTransSavingPotential.final.total
                        )}
                      </LabelSaving>
                    </dd>
                  </dl>
                </TableContainer>

                <FormWrapper id="request-a-demo">
                  <FormHeader>
                    <FormTitle>Want the full report?</FormTitle>
                    <Paragraph3Custom>
                      Fill out the short form below to get the full report sent
                      to your email.
                    </Paragraph3Custom>
                  </FormHeader>
                  <HubspotFormRaw {...shipperGuideROICalcOptions} />
                </FormWrapper>
              </TableWrapper>
            </CalculatorWrapper>
          </ContentHolder>
        </Container>
      </WideCalculatorWrapper>
    </>
  );
}
