/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';

// components
import { Container } from 'components/Grid';
import { SectionTitle } from '@/components/SectionTitle';

//vendor
import ReactPlayer from 'react-player/lazy';

// styles
import {
  SectionWrapper,
  TitleWrapper,
  MediaWrapper,
  VideoWrapper,
  Picture,
  ClosingIcon,
  PlayingIcon,
  Thumbnail,
} from './VideoPlayer.styles';

//icons
import IconPlay from '@/public/images/carrier-tms/video-hero/icon-play.svg';
import { IconClose } from '@loadsmart/icons';

export function VideoPlayer() {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <SectionTitle
            title={
              <>
                Easy procurement and
                <br /> execution, in one place.
              </>
            }
            subtitle={`Say goodbye to complex logistics systems`}
            variant="center"
          />
        </TitleWrapper>

        <MediaWrapper>
          <VideoWrapper className={playVideo ? `active` : ``}>
            <ClosingIcon
              onClick={() => {
                setPlayVideo(false);
              }}
            >
              <IconClose />
            </ClosingIcon>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=_BjZ4WAsN74?rel=0"
              controls
              playing={playVideo}
              width="100%"
              height="100%"
            />
          </VideoWrapper>
          <Thumbnail
            className={playVideo ? `inactive` : ``}
            onClick={() => {
              setPlayVideo(true);
            }}
            role="button"
          >
            <Picture>
              <source
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                media="(min-width: 768px)"
              />
              <img
                srcSet={`/images/shipperguide/image-cta/shipperguide-dashboard@2x.png`}
                alt="Shipper Guide dashboard screen"
                width="677"
                height="520"
              />
            </Picture>
            <PlayingIcon>
              <IconPlay />
            </PlayingIcon>
          </Thumbnail>
        </MediaWrapper>
      </Container>
    </SectionWrapper>
  );
}
