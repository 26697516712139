/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';

//slider
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

//components
import { Container } from '@/components/Grid';

// embed form
import { managedTransROICalcOptions } from '@/vendor/HubspotFormIntegration';

//styles
import {
  WideCalculatorWrapper,
  ContentHolder,
  SectionHeader,
  TitleCustom,
  ParagraphCustom,
  SubtitleCustom,
  Paragraph2Custom,
  Paragraph3Custom,
  CalculatorWrapper,
  SliderContainer,
  TableWrapper,
  FormWrapper,
  FormHeader,
  FormTitle,
  SliderWrapper,
  //
  LabelAmount,
  LabelEstimated,
  LabelSaving,
} from './WideCalculator.styles';
import { HubspotFormRaw } from '../HubspotFormRaw';

const baseData = {
  companyRevenue: 0,
  estimatedTransSpend: {
    percent_5: 0,
    percent_10: 0,
  },
  estimatedSavingsOp: {
    carrierRateReductions: {
      withTransSpendOf_5: {
        from: 0,
        to: 0,
      },
      withTransSpendOf_10: {
        from: 0,
        to: 0,
      },
    },
    optimizationSavings: {
      withTransSpendOf_5: {
        from: 0,
        to: 0,
      },
      withTransSpendOf_10: {
        from: 0,
        to: 0,
      },
    },
    freightAuditSavings: {
      withTransSpendOf_5: {
        from: 0,
        to: 0,
      },
      withTransSpendOf_10: {
        from: 0,
        to: 0,
      },
    },
  },
  cumulativeTransSavingPotential: {
    withTransSpendOf_5: {
      from: 0,
      to: 0,
    },
    withTransSpendOf_10: {
      from: 0,
      to: 0,
    },
  },
};

// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const shortFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumSignificantDigits: 3,
});

const calcField = [];

export function WideCalculator({}) {
  const [userData, setUserData] = useState(baseData);
  const [baseValue, setBaseValue] = useState(50000000);
  const [load, setLoad] = useState(false);

  function watchSubmit() {
    document.addEventListener('submit', function (event) {
      setLoad(false);
    });
  }

  function setupFields() {
    window.addEventListener('message', (event) => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormReady' &&
        event.data.id === managedTransROICalcOptions.formId
      ) {
        calcField['companyRevenue'] = document
          .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___1"]'
          );
        calcField['estimatedTransSpend-percent_5'] = document
          .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___2"]'
          );
        calcField['estimatedTransSpend-percent_10'] = document
          .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___3"]'
          );
        calcField[
          'estimatedSavingsOp-carrierRateReductions-withTransSpendOf_5'
        ] = document
          .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___4"]'
          );
        calcField[
          'estimatedSavingsOp-carrierRateReductions-withTransSpendOf_10'
        ] = document
          .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___5"]'
          );
        calcField['estimatedSavingsOp-optimizationSavings-withTransSpendOf_5'] =
          document
            .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
            .querySelector(
              'input[name="roi_calculator_value___report_by_email___6"]'
            );
        calcField[
          'estimatedSavingsOp-optimizationSavings-withTransSpendOf_10'
        ] = document
          .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___7"]'
          );
        calcField['estimatedSavingsOp-freightAuditSavings-withTransSpendOf_5'] =
          document
            .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
            .querySelector(
              'input[name="roi_calculator_value___report_by_email___8"]'
            );
        calcField[
          'estimatedSavingsOp-freightAuditSavings-withTransSpendOf_10'
        ] = document
          .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
          .querySelector(
            'input[name="roi_calculator_value___report_by_email___9"]'
          );
        calcField['cumulativeTransSavingPotential-withTransSpendOf_5'] =
          document
            .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
            .querySelector(
              'input[name="roi_calculator_value___report_by_email___10"]'
            );
        calcField['cumulativeTransSavingPotential-withTransSpendOf_10'] =
          document
            .getElementById('hsForm_849efbae-0964-469f-a6a9-77f1acf61f4d')
            .querySelector(
              'input[name="roi_calculator_value___report_by_email___11"]'
            );
      }

      if (!load) {
        roiCalculator(baseValue);
        setLoad(true);
      }
    });
  }

  // onChangeHandle
  function roiCalculator(base) {
    const estimated_base_5 = base * 0.05;
    const estimated_base_10 = base * 0.1;

    let dataCopy = {
      companyRevenue: base,
      estimatedTransSpend: {
        percent_5: estimated_base_5,
        percent_10: estimated_base_10,
      },
      estimatedSavingsOp: {
        carrierRateReductions: {
          withTransSpendOf_5: {
            from: estimated_base_5 * 0.03,
            to: estimated_base_5 * 0.1,
          },
          withTransSpendOf_10: {
            from: estimated_base_10 * 0.03,
            to: estimated_base_10 * 0.1,
          },
        },
        optimizationSavings: {
          withTransSpendOf_5: {
            from: estimated_base_5 * 0.15,
            to: estimated_base_5 * 0.4,
          },
          withTransSpendOf_10: {
            from: estimated_base_10 * 0.15,
            to: estimated_base_10 * 0.4,
          },
        },
        freightAuditSavings: {
          withTransSpendOf_5: {
            from: estimated_base_5 * 0.05,
            to: 0,
          },
          withTransSpendOf_10: {
            from: estimated_base_10 * 0.05,
            to: 0,
          },
        },
      },
      cumulativeTransSavingPotential: null,
    };

    const hadleSumUp = {
      withTransSpendOf_5: {
        from:
          dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_5
            .from +
          dataCopy.estimatedSavingsOp.freightAuditSavings.withTransSpendOf_5
            .from +
          dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_5
            .from,
        to:
          dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_5
            .to +
          dataCopy.estimatedSavingsOp.freightAuditSavings.withTransSpendOf_5
            .to +
          dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_5.to,
      },
      withTransSpendOf_10: {
        from:
          dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_10
            .from +
          dataCopy.estimatedSavingsOp.freightAuditSavings.withTransSpendOf_10
            .from +
          dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_10
            .from,
        to:
          dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_10
            .to +
          dataCopy.estimatedSavingsOp.freightAuditSavings.withTransSpendOf_10
            .to +
          dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_10
            .to,
      },
    };

    dataCopy = { ...dataCopy, cumulativeTransSavingPotential: hadleSumUp };
    setUserData(dataCopy);

    if (calcField['companyRevenue']) {
      // fill hub hidden inputs
      calcField['companyRevenue'].value = shortFormatter.format(
        dataCopy.companyRevenue
      );
      calcField['estimatedTransSpend-percent_5'].value = shortFormatter.format(
        dataCopy.estimatedTransSpend.percent_5
      );
      calcField['estimatedTransSpend-percent_10'].value = shortFormatter.format(
        dataCopy.estimatedTransSpend.percent_10
      );
      calcField[
        'estimatedSavingsOp-carrierRateReductions-withTransSpendOf_5'
      ].value = `From ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_5
          .from
      )} to ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_5.to
      )}`;
      calcField[
        'estimatedSavingsOp-carrierRateReductions-withTransSpendOf_10'
      ].value = `From ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_10
          .from
      )} to ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.carrierRateReductions.withTransSpendOf_10.to
      )}`;
      calcField[
        'estimatedSavingsOp-optimizationSavings-withTransSpendOf_5'
      ].value = `From ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_5.from
      )} to ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_5.to
      )}`;
      calcField[
        'estimatedSavingsOp-optimizationSavings-withTransSpendOf_10'
      ].value = `From ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_10.from
      )} to ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.optimizationSavings.withTransSpendOf_10.to
      )}`;
      calcField[
        'estimatedSavingsOp-freightAuditSavings-withTransSpendOf_5'
      ].value = `Of ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.freightAuditSavings.withTransSpendOf_5.from
      )}`;
      calcField[
        'estimatedSavingsOp-freightAuditSavings-withTransSpendOf_10'
      ].value = `Of ${shortFormatter.format(
        dataCopy.estimatedSavingsOp.freightAuditSavings.withTransSpendOf_10.from
      )}`;
      calcField[
        'cumulativeTransSavingPotential-withTransSpendOf_5'
      ].value = `From ${shortFormatter.format(
        dataCopy.cumulativeTransSavingPotential.withTransSpendOf_5.from
      )} to ${shortFormatter.format(
        dataCopy.cumulativeTransSavingPotential.withTransSpendOf_5.to
      )}`;
      calcField[
        'cumulativeTransSavingPotential-withTransSpendOf_10'
      ].value = `From ${shortFormatter.format(
        dataCopy.cumulativeTransSavingPotential.withTransSpendOf_10.from
      )} to ${shortFormatter.format(
        dataCopy.cumulativeTransSavingPotential.withTransSpendOf_10.to
      )}`;
    }
  }

  useEffect(() => {
    if (!load) {
      setupFields();
      watchSubmit();
    }
  });

  useEffect(() => {
    roiCalculator(baseValue);
  }, [baseValue]);

  //show form

  return (
    <>
      <WideCalculatorWrapper>
        <Container id="roi-calculator">
          <ContentHolder>
            <SectionHeader>
              <TitleCustom>
                Use this calculator to see how much Loadsmart Managed
                Transportation can save you on freight!
              </TitleCustom>

              <ParagraphCustom>
                We’ve gathered data from real organizations currently leveraging
                Loadsmart Managed Transportation solutions to develop a
                calculator that predicts how much your company could be saving!{' '}
              </ParagraphCustom>
            </SectionHeader>

            <CalculatorWrapper>
              <SliderContainer>
                <SubtitleCustom>
                  Enter Your Company’s Annual Revenue:
                </SubtitleCustom>
                <Paragraph2Custom>
                  Use the scroll bar to enter your company’s annual revenue and
                  see your potential savings for yourself.
                </Paragraph2Custom>
                <LabelAmount>${shortFormatter.format(baseValue)}</LabelAmount>

                <SliderWrapper>
                  {load && (
                    <Slider
                      min={10000000}
                      max={2000000000}
                      step={10000000}
                      value={baseValue}
                      tooltip={false}
                      onChange={(value) => {
                        setBaseValue(value);
                      }}
                    />
                  )}
                </SliderWrapper>

                <Paragraph2Custom>
                  Estimated Transportation Spend (5-10% Revenue):
                </Paragraph2Custom>
                <LabelEstimated>
                  $
                  {shortFormatter.format(
                    userData.estimatedTransSpend.percent_5
                  )}{' '}
                  to $
                  {shortFormatter.format(
                    userData.estimatedTransSpend.percent_10
                  )}
                </LabelEstimated>
              </SliderContainer>

              <TableWrapper>
                {/* results */}
                <div>
                  <FormTitle>Optimization Savings </FormTitle>
                  <Paragraph3Custom>
                    With Transportation Spend of $
                    {shortFormatter.format(
                      userData.estimatedTransSpend.percent_5
                    )}
                  </Paragraph3Custom>
                  <div>
                    <LabelSaving>
                      {formatter.format(
                        userData.estimatedSavingsOp.optimizationSavings
                          .withTransSpendOf_5.from
                      )}
                    </LabelSaving>{' '}
                    &nbsp;&nbsp;
                    <LabelSaving>
                      <span>to</span>
                    </LabelSaving>{' '}
                    &nbsp;&nbsp;
                    <LabelSaving>
                      {formatter.format(
                        userData.estimatedSavingsOp.optimizationSavings
                          .withTransSpendOf_5.to
                      )}
                    </LabelSaving>
                  </div>
                  <Paragraph3Custom>
                    With Transportation Spend of $
                    {shortFormatter.format(
                      userData.estimatedTransSpend.percent_10
                    )}
                  </Paragraph3Custom>
                  <div>
                    <LabelSaving>
                      {formatter.format(
                        userData.estimatedSavingsOp.optimizationSavings
                          .withTransSpendOf_10.from
                      )}
                    </LabelSaving>{' '}
                    &nbsp;&nbsp;
                    <LabelSaving>
                      <span>to</span>
                    </LabelSaving>{' '}
                    &nbsp;&nbsp;
                    <LabelSaving>
                      {' '}
                      {formatter.format(
                        userData.estimatedSavingsOp.optimizationSavings
                          .withTransSpendOf_10.to
                      )}
                    </LabelSaving>
                  </div>
                </div>

                <FormWrapper id="request-a-demo">
                  <FormHeader>
                    <FormTitle>Would you like to see more savings?</FormTitle>
                    <Paragraph3Custom>
                      Discover how much you could save on Carrier Rates and with
                      Freight Audit, and see your estimated Cumulative
                      Transportation Savings, too!
                    </Paragraph3Custom>
                  </FormHeader>
                  <HubspotFormRaw {...managedTransROICalcOptions} />
                </FormWrapper>
              </TableWrapper>
            </CalculatorWrapper>
          </ContentHolder>
        </Container>
      </WideCalculatorWrapper>
    </>
  );
}
