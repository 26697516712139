import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//components
import { MirandaButton } from '@/components/Button/BaseButton';

export const SideCTAWrapper = styled.section`
  display: flex;
  width: 100%;
  color: rgb(${getToken('color-primary-100')});
  background: ${({ theme }) => theme.color.neutral.lighter3};
  padding: 60px 0;

  & > div {
    display: grid;
    justify-content: space-between;
  }

  ${screen.lg} {
    padding: 120px 0;
    min-height: 568px;

    & > div {
      grid-template-columns: 55% auto;
    }
  }

  ${screen.xxlg} {
    padding: 100px 0;
    min-height: 778px;
  }
`;

export const TitleWrapper = styled.div`
  margin: auto 0 70px;

  ${screen.lg} {
    margin: auto 0;
  }
`;

export const CTAs = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 50px;

  ${screen.md} {
    margin-top: 0px;
    gap: 24px;
    flex-direction: row;
  }
`;

export const Columns = styled.div`
  display: grid;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;

  ${screen.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledButton = styled(MirandaButton)`
  padding: 8px 16px;
  color: rgb(${getToken('color-brand')});

  min-width: 250px;

  ${screen.md} {
    min-width: unset;
  }

  ${screen.lg} {
    padding: 12px 24px;
  }

  ${screen.xxlg} {
    padding: 16px 38px;
  }
`;

export const StyledButtonHollow = styled(StyledButton)`
  color: rgb(${getToken('color-primary-100')});
  background-color: transparent;
  border: 2px solid rgb(${getToken('color-brand')});

  &:hover,
  &:active {
    color: rgb(${getToken('color-primary-60')});
    background: rgb(${getToken('color-background-tertiary')});
    border-color: rgb(${getToken('color-primary-60')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-primary-100')});
  }
`;
