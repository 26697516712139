import { useEffect, useState } from 'react';
import ScrollBooster from 'scrollbooster';

//components
import { Container } from '@/components/Grid';
import { ContentPill } from '@/components/ContentPill';
import { SectionTitle } from '@/components/SectionTitle';

//styles
import {
  TabsContentWrapper,
  TitleWrapper,
  //-
  TabSectionWrapper,
  TabsNavigation,
  NavigationList,
  NavItem,
  NavItemIcon,
  NavItemLabel,
  //,
  TabsSlider,
  TabSliderBackground,
  TabList,
  Tab,
  ContentHolder,
  ContentTitle,
  ContentDescription,
  TagsSoon,
  //,
  SubitemWrapper,
  //-
  MediaHolder,
  ArrowsHolder,
  TabPrev,
  TabNext,
} from './TabsContent.styles';

//assets - icons - nav
import Truck from '@/public/images/icons/loadsmart-collection/037-truck-bold.svg';
import Plan from '@/public/images/icons/loadsmart-collection/030-board.svg';
import Execute from '@/public/images/icons/loadsmart-collection/003-sg-execute.svg';
import Settle from '@/public/images/icons/loadsmart-collection/002-sg-settle.svg';
import Analyze from '@/public/images/icons/loadsmart-collection/001-sg-analyze.svg';

//assets - icons - tabs
import Bids from '@/public/images/icons/loadsmart-collection/005-sg-rfps-mini-bids.svg';
import Award from '@/public/images/icons/loadsmart-collection/004-sg-award-scenarios.svg';
import RealTime from '@/public/images/icons/loadsmart-collection/031-sg-real-time-carrier-bids.svg';
import Spot from '@/public/images/icons/loadsmart-collection/035-sg-benchmark-rates.svg';
// -
import Routing from '@/public/images/icons/loadsmart-collection/029-sg-plan.svg';
import Order from '@/public/images/icons/loadsmart-collection/032-sg-order-consolidation.svg';
import Mode from '@/public/images/icons/loadsmart-collection/033-sg-mode-optimization.svg';
import Selection from '@/public/images/icons/loadsmart-collection/014-sg-carrier-selection.svg';
// -
import Tender from '@/public/images/icons/loadsmart-collection/024-sg-tender-shipments.svg';
import Tracking from '@/public/images/icons/loadsmart-collection/028-sg-tracking-visibility.svg';
import Appointment from '@/public/images/icons/loadsmart-collection/034-sg-appointment-scheduling.svg';
import Document from '@/public/images/icons/loadsmart-collection/027-sg-document-generation.svg';
// -
import Charges from '@/public/images/icons/loadsmart-collection/026-sg-accessorials-charges.svg';
import Audit from '@/public/images/icons/loadsmart-collection/007-sg-audit-dispute.svg';
import DocManagement from '@/public/images/icons/loadsmart-collection/025-sg-document-management.svg';
import Payment from '@/public/images/icons/loadsmart-collection/023-sg-carrier-payment.svg';
// -
import Data from '@/public/images/icons/loadsmart-collection/021-sg-real-time-data.svg';
import Performance from '@/public/images/icons/loadsmart-collection/039-economy.svg';
import Reports from '@/public/images/icons/loadsmart-collection/020-sg-customizable-reports.svg';
import Scorecards from '@/public/images/icons/loadsmart-collection/022-sg-carrier-scorecards.svg';
// -

// - images tabs
import Tab01 from '@/public/images/shipperguide/tabs/items/Tab-01.svg';
import Tab02 from '@/public/images/shipperguide/tabs/items/Tab-02.svg';
import Tab03 from '@/public/images/shipperguide/tabs/items/Tab-03.svg';
import Tab04 from '@/public/images/shipperguide/tabs/items/Tab-04.svg';
import Tab05 from '@/public/images/shipperguide/tabs/items/Tab-05.svg';

const tabs_content = [
  {
    hash: 'procure',
    label: 'Procure',
    icon: <Truck width="32" height="32" />,
    content: {
      title: 'Procure Spot & Contract',
      description: (
        <>
          Streamline your freight procurement process with a centralized
          solution. Find fair pricing and high quality carriers for all your
          contracted and spot lanes.
        </>
      ),
      media: <Tab01 width="834" height="610" />,
      items: [
        {
          icon: <Bids width="28" height="28" />,
          title: 'RFPs & Mini-Bids',
          description: (
            <>
              Simplify your procurement process by creating bids for contracts,
              aggregating lanes and benchmarking your rates.
            </>
          ),
        },
        {
          icon: <Award width="28" height="28" />,
          title: 'Award Scenarios',
          description: (
            <>
              Set business decision rules to ensure optimal and automated
              carrier awards on hundreds of lanes in a matter of minutes.
            </>
          ),
        },
        {
          icon: <RealTime width="28" height="28" />,
          title: 'Real-Time Carrier Bids',
          description: (
            <>
              <strong>CarrierGuide </strong> is an interface provided to
              carriers so you can get real-time bids and negotiate.
            </>
          ),
        },
        {
          icon: <Spot width="28" height="28" />,
          title: 'Spot Bids',
          description: (
            <>
              Get quotes from carriers for shipments that need to be moved on
              short notice or outside of regular contracted agreements.
            </>
          ),
        },
      ],
    },
  },
  {
    hash: 'plan',
    label: 'Plan',
    icon: <Plan width="32" height="32" />,
    content: {
      title: 'Plan & Optimize',
      description: (
        <>
          Identify the best strategies for your specific business needs, such as
          reducing transportation costs, improving delivery times, and enhancing
          service quality.
        </>
      ),
      media: <Tab02 width="834" height="610" />,
      items: [
        {
          icon: <Routing width="28" height="28" />,
          title: 'Routing Guide',
          description: (
            <>
              Create and import carrier contracts, monitor all lanes and improve
              performance through insights.
            </>
          ),
        },
        {
          icon: <Order width="28" height="28" />,
          title: 'Rate Benchmarking',
          description: (
            <>
              Ensure that you are paying a fair price for your freight by
              comparing your rates with the market standards.
            </>
          ),
        },
        {
          icon: <Mode width="28" height="28" />,
          title: 'Mode Optimization',
          description: (
            <>
              Select the optimal transportation mode for your shipments to meet
              your delivery requirements while minimizing costs.
            </>
          ),
        },
        {
          icon: <Selection width="28" height="28" />,
          title: 'Carrier Selection',
          description: (
            <>
              Select the best carrier by comparing service and rates
              side-by-side and make informed decisions.
            </>
          ),
        },
      ],
    },
  },
  {
    hash: 'execute',
    label: 'Execute',
    icon: <Execute width="32" height="32" />,
    content: {
      title: 'Execute Shipments',
      description: (
        <>
          Achieve greater operational efficiency and deliver a superior customer
          experience with full control over your operations. Make informed
          decisions and quickly adapt to changing circumstances.
        </>
      ),
      media: <Tab03 width="834" height="610" />,
      items: [
        {
          icon: <Tender width="28" height="28" />,
          title: 'Tender Shipments',
          description: (
            <>
              Select the optimal carrier to haul and tender shipments via EDI or
              the free carrier portal <strong>CarrierGuide</strong>.
            </>
          ),
        },
        {
          icon: <Tracking width="28" height="28" />,
          title: 'Tracking & Visibility',
          description: (
            <>
              Monitor your shipments from start to finish, providing peace of
              mind and ensuring timely delivery.
            </>
          ),
        },
        {
          icon: <Appointment width="28" height="28" />,
          title: 'Appointment Scheduling',
          description: (
            <>
              Schedule appointments with carriers for more efficient loading and
              unloading of shipments.
            </>
          ),
        },
        {
          icon: <Document width="28" height="28" />,
          title: 'Document Generation',
          description: (
            <>
              Generate all necessary shipping documents automatically, saving
              time and reducing errors.
            </>
          ),
        },
      ],
    },
  },
  {
    hash: 'settle',
    label: 'Settle',
    icon: <Settle width="32" height="32" />,
    content: {
      title: (
        <>
          Settle <TagsSoon>Soon</TagsSoon>
        </>
      ),
      description: (
        <>
          Ensure that all financial aspects of your shipments are taken care of
          accurately and efficiently, leaving you with more time to focus on
          your business goals.
        </>
      ),
      media: <Tab04 width="834" height="610" />,
      items: [
        {
          icon: <Charges width="28" height="28" />,
          title: 'Accessorials Charges',
          description: (
            <>
              Manage additional charges that may be associated with your
              shipments, such as fees or extra handling.
            </>
          ),
        },
        {
          icon: <Audit width="28" height="28" />,
          title: 'Audit & Dispute',
          description: (
            <>
              Conduct audits on your shipments invoices and quickly resolve any
              disputes or discrepancies that arise.
            </>
          ),
        },
        {
          icon: <DocManagement width="28" height="28" />,
          title: 'Document Management',
          description: (
            <>
              Easily organize and manage all of the documents related to your
              shipments such as bills of lading, proof of delivery and more.
            </>
          ),
        },
        {
          icon: <Payment width="28" height="28" />,
          title: 'Carrier Payment',
          description: (
            <>
              Timely pay carriers for their services, helping to maintain
              positive relationships and avoid payment disputes.
            </>
          ),
        },
      ],
    },
  },
  {
    hash: 'analyze',
    label: 'Analyze',
    icon: <Analyze width="32" height="32" />,
    content: {
      title: <>Analyze</>,
      description: (
        <>
          Gather valuable insights and data on your shipments, allowing you to
          make informed decisions and optimize your strategy. Gain a competitive
          edge and achieve greater efficiency in your logistics operations.
        </>
      ),
      media: <Tab05 width="834" height="610" />,
      items: [
        {
          icon: <Data width="28" height="28" />,
          title: 'Real-Time Data',
          description: (
            <>
              Access real-time data and analytics on your shipments, allowing
              you to track performance and make informed decisions.
            </>
          ),
        },
        {
          icon: <Performance width="28" height="28" />,
          title: (
            <>
              <span>Performance Monitor</span>{' '}
              <TagsSoon className="inner-tag">Soon</TagsSoon>
            </>
          ),
          description: (
            <>
              See how your operations are going, how they have been fluctuating
              and how they compare to benchmarks.
            </>
          ),
        },
        {
          icon: <Reports width="28" height="28" />,
          title: 'Customizable Reports',
          description: (
            <>
              Create customized reports based on your specific needs, helping
              you to analyze trends and make data-driven decisions.
            </>
          ),
        },
        {
          icon: <Scorecards width="28" height="28" />,
          title: 'Carrier Scorecards',
          description: (
            <>
              Evaluate carrier performance based on key metrics, helping you to
              optimize your carrier selection and improve performance.
            </>
          ),
        },
      ],
    },
  },
];

export function TabsContent() {
  const [SB, setSB] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const MoveCursor = (newkey) => {
    setActiveTab(newkey);
    SB.setPosition({
      x: newkey * 100,
      y: 0,
    });
  };

  useEffect(() => {
    setSB(
      new ScrollBooster({
        viewport: document.querySelector('.nav-view'),
        content: document.querySelector('.nav-list'),
        scrollMode: 'transform', // use CSS 'transform' property
        direction: 'horizontal', // allow only horizontal scrolling
        emulateScroll: true, // scroll on wheel events
        bounce: false, // elastic bounce effect
      })
    );
  }, []);

  return (
    <>
      <TabsContentWrapper>
        <Container>
          <TitleWrapper>
            <SectionTitle
              title={`The Shipper’s Software with features you need and none of the fluff you don’t.`}
              subtitle={`End-to-end load visibility`}
              variant="center"
            />
          </TitleWrapper>
        </Container>

        {tabs_content.length > -1 && (
          <TabSectionWrapper>
            <TabsNavigation className="nav-view">
              <NavigationList className="nav-list" items={tabs_content.length}>
                {tabs_content.map((item, key) => {
                  return (
                    <NavItem
                      className={activeTab === key ? `active` : ``}
                      key={item.hash + `-` + key}
                      onClick={() => MoveCursor(key)}
                      role="button"
                    >
                      <NavItemIcon>{item.icon}</NavItemIcon>
                      <NavItemLabel>{item.label}</NavItemLabel>
                    </NavItem>
                  );
                })}
              </NavigationList>
            </TabsNavigation>
            <TabsSlider>
              <TabSliderBackground>
                {tabs_content.length > -1 && (
                  <TabList>
                    {tabs_content.map((tab, key) => {
                      return (
                        <Tab
                          key={`tab-` + tab.hash + `-` + key}
                          className={activeTab === key ? `active` : ``}
                        >
                          <ContentHolder>
                            <div>
                              <ContentTitle>{tab.content.title}</ContentTitle>
                              <ContentDescription>
                                {tab.content.description}
                              </ContentDescription>
                              <SubitemWrapper>
                                {tab.content.items.length > -1 &&
                                  tab?.content?.items.map((subitem, keyt) => {
                                    return (
                                      <ContentPill
                                        key={`subi-` + key + `-` + keyt}
                                        content={subitem}
                                      />
                                    );
                                  })}
                              </SubitemWrapper>
                            </div>
                            <ArrowsHolder>
                              {tabs_content[activeTab - 1] && (
                                <TabPrev
                                  onClick={() =>
                                    activeTab != 0 &&
                                    setActiveTab(activeTab - 1)
                                  }
                                >
                                  {tabs_content[activeTab - 1]?.label}
                                </TabPrev>
                              )}

                              {tabs_content[activeTab + 1] && (
                                <TabNext
                                  onClick={() =>
                                    activeTab != tabs_content.length &&
                                    setActiveTab(activeTab + 1)
                                  }
                                >
                                  {tabs_content[activeTab + 1]?.label}
                                </TabNext>
                              )}
                            </ArrowsHolder>
                          </ContentHolder>

                          <MediaHolder>
                            {activeTab === key && tab?.content?.media && (
                              <>{tab.content.media}</>
                            )}
                          </MediaHolder>
                        </Tab>
                      );
                    })}
                  </TabList>
                )}
              </TabSliderBackground>
            </TabsSlider>
          </TabSectionWrapper>
        )}
      </TabsContentWrapper>
    </>
  );
}
