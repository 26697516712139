import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { Title } from '@/components/Grid';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const BackgroundSection = styled.section`
  background: rgb(${getToken('color-background-primary')});
  overflow: hidden;
  padding: 40px 0;
  position: relative;

  ${screen.md} {
    padding: 60px 0;
  }

  ${screen.lg} {
    padding: 80px 0;
  }

  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const TitleWrapper = styled.div`
  margin: 0 auto 40px;

  h3 {
    text-align: center;
  }

  ${screen.md} {
    margin: 0 auto 60px;
  }
`;

export const SectionTitle = styled(Title)`
  color: rgb(${getToken('color-text-primary')});
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;
`;

export const FooterWrapper = styled.div`
  padding: 48px 0 8px;
  border-bottom: 1px solid rgb(${getToken('color-neutral-40')});

  p {
    color: rgb(${getToken('color-neutral-60')});
    font-family: ${getToken('platform-font-default')};
    font-size: 18px;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
`;

export const Carousel = styled.div`
  li.react-multi-carousel-item {
    transition: all 0.1s ease-in-out;
  }
  li:not(.react-multi-carousel-item--active) {
    div {
      background: rgb(${getToken('color-background-tertiary')});
      transition: all 0.1s ease-in-out;

      * {
        color: rgb(${getToken('color-text-disabled')});
        transition: all 0.1s ease-in-out;
      }
    }
  }
`;

export const Slide = styled.div`
  width: calc(100% - 16px);
  padding: 28px;

  flex: 0 0 auto;
  height: 100%;

  border-radius: 8px;
  background: rgb(${getToken('color-neutral-100')});

  * {
    color: rgb(${getToken('color-text-inverted')});
    text-align: center;
  }

  ${screen.md} {
    width: 95%;
  }

  ${screen.xxlg} {
    min-width: 440px;
  }
`;

export const SlideButtonLeft = styled.button`
  display: grid;
  text-align: center;
  position: absolute;
  background: rgb(${getToken('color-brand')});
  border-radius: 60px;
  z-index: 5;
  border: none;
  cursor: pointer;
  transform: rotate(180deg);

  width: 40px;
  height: 40px;

  left: calc(4% + 1px);

  opacity: 0.7;

  &:hover,
  &:active {
    opacity: 1;
  }

  ${screen.xxlg} {
    width: 59px;
    height: 59px;
  }

  svg {
    color: rgb(${getToken('color-text')});
    path: rgb(${getToken('color-text')});
    margin: auto;
  }
`;

export const SlideButtonRight = styled(SlideButtonLeft)`
  left: unset;
  transform: rotate(0deg);

  right: calc(4% + 1px);
`;
