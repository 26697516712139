import Lottie from 'react-lottie';
import { useInView } from 'react-intersection-observer';
import { useRouter } from 'next/router';

//components
import { Container } from '@/components/Grid';
import { AnimatedBanner } from '@/containers-m/AnimatedBanner';

//styles
import {
  HeroWrapper,
  ContainerLogo,
  ContainerHero,
  TopLogoWrapper,
  ContentHolder,
  MediaHolder,
  AnimationHolder,
  AwardsHolder,
  BadgeContainer,
  Badge,
  TagSlogan,
  HeroTitle,
  HeroContent,
  CTAHolder,
  BrandButton,
  BrandHollowButton,
} from './Hero.styles';

//assets
import ShipperGuideLogo from '@/public/images/logos/shipperguide-logo-white.svg';
import AnimationData from '@/datafiles/lottie/shipperguide/hero-animation.json';

const lottieDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: AnimationData,
};

const adBanner = {
  image: '/images/animated-banner/screaming_man.png',
  copy: (
    <>
      Drowning in a sea of freight management emails? <br />
    </>
  ),
  linkCopy: 'Click here for help!',
  link: '#hubspot-form',
};

const adBanner2 = {
  image: '/images/animated-banner/screaming_man_2.png',
  copy: (
    <>
      6 figure price tag for a TMS
      <br /> keeping you up at night? <br />
    </>
  ),
  linkCopy: 'Click here for help!',
  link: '#hubspot-form',
};

export function Hero() {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const router = useRouter();

  return (
    <>
      {router.query.utm_content === 'scary-email' && (
        <AnimatedBanner content={adBanner} />
      )}
      {router.query.utm_content === 'tms-price' && (
        <AnimatedBanner content={adBanner2} />
      )}
      <HeroWrapper inView={inView}>
        <Container>
          <ContainerLogo>
            <TopLogoWrapper>
              <ShipperGuideLogo alt="ShipperGuide TMS" />
            </TopLogoWrapper>
            &nbsp;TMS
          </ContainerLogo>
        </Container>
        <ContainerHero>
          <ContentHolder>
            <TagSlogan>MOVE MORE WITH LESS</TagSlogan>
            <HeroTitle>
              Optimize <strong>shipping operations</strong> with our next
              generation <strong>Transportation Management System</strong>.
            </HeroTitle>
            <HeroContent>
              Grow your business and unify your operations with ShipperGuide
              TMS. Discover and manage rates with any provider of choice, easily
              tender shipments and share real-time tracking with customers and
              your team.
            </HeroContent>
            <CTAHolder>
              <BrandButton as="a" href="#hubspot-form">
                REQUEST A DEMO
              </BrandButton>
              <BrandHollowButton
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.SHIPPERGUIDE_URL}/signup`}
              >
                TRY SHIPPERGUIDE TMS NOW
              </BrandHollowButton>
            </CTAHolder>
          </ContentHolder>
          <MediaHolder ref={ref}>
            {inView && (
              <>
                <AnimationHolder>
                  <Lottie options={lottieDefaultOptions} />
                </AnimationHolder>
                <AwardsHolder>
                  <BadgeContainer>
                    <Badge
                      width="93.92"
                      height="122"
                      src="/images/awards/g2-logos/FreightManagementBestEstimatedROI.png"
                      alt="Freight Management Best Estimated ROI"
                    />
                  </BadgeContainer>
                  <BadgeContainer>
                    <Badge
                      width="93.92"
                      height="122"
                      src="/images/awards/g2-logos/TransportationManagementSystemsTMSLeader.png"
                      alt="Transportation Management Systems TMS Leader"
                    />
                  </BadgeContainer>
                  <BadgeContainer>
                    <Badge
                      width="93.92"
                      height="122"
                      src="/images/awards/g2-logos/EasiesttoDoBusinessWith.png"
                      alt="Easiest to do Business With"
                    />
                  </BadgeContainer>
                  <BadgeContainer>
                    <Badge
                      width="93.92"
                      height="122"
                      src="/images/awards/g2-logos/MidMarketLeader.png"
                      alt="Mid Market Leader"
                    />
                  </BadgeContainer>
                  <BadgeContainer>
                    <Badge
                      width="93.92"
                      height="122"
                      src="/images/awards/g2-logos/EasiestSetUp.png"
                      alt="Easiest Set Up"
                    />
                  </BadgeContainer>
                  <BadgeContainer>
                    <Badge
                      width="93.92"
                      height="122"
                      src="/images/awards/g2-logos/BestSupportforMidMarket.png"
                      alt="Best Support for Mid Market"
                    />
                  </BadgeContainer>
                </AwardsHolder>
              </>
            )}
          </MediaHolder>
        </ContainerHero>
      </HeroWrapper>
    </>
  );
}
