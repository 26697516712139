// styles
import { useState } from 'react';
import {
  PageWrapper,
  AnimatedImage,
  StyledContainer,
  BannerContent,
  CloseButton,
  Picture,
} from './AnimatedBanner.styles';

import { IconClose } from '@loadsmart/icons';

export function AnimatedBanner({ content }) {
  const [isShowing, setIsShowing] = useState(null);
  return (
    <PageWrapper>
      <StyledContainer
        onMouseOver={
          isShowing === null ? () => setIsShowing('visible') : () => {}
        }
        className={isShowing}
      >
        <AnimatedImage src={content.image} />
        <BannerContent>
          <i>
            {content.copy}
            <a href={content.link} onClick={() => setIsShowing('hidden')}>
              {content.linkCopy}
            </a>
          </i>
        </BannerContent>
        <CloseButton onClick={() => setIsShowing('hidden')}>
          <IconClose />
        </CloseButton>
      </StyledContainer>
    </PageWrapper>
  );
}
