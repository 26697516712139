import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//helpers
import { screen } from '@/helpers/screen';
import conditional from '@/helpers/conditional';

export const TitleWrapper = styled.div`
  ${screen.md} {
    margin: 20px auto;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-primary-100')});

  text-align: ${conditional({
    center: (props) => props.variant === 'center',
    left: (props) => props.variant === 'left',
  })};

  font-size: 28px;
  line-height: 32.2px;
  margin: auto;

  .showOnMobile {
    display: block;
  }

  ${screen.md} {
    font-size: 38px;
    line-height: 43.7px;

    margin: 20px auto 30px;

    .showOnMobile {
      display: none;
    }
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 66.7px;
  }
`;

export const Subtitle = styled.p`
  font-family: ${getToken('platform-font-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-60')});
  text-align: center;

  font-size: 14px;
  line-height: 22.4px;

  text-align: ${conditional({
    center: (props) => props.variant === 'center',
    left: (props) => props.variant === 'left',
  })};

  ${screen.md} {
    font-size: 16px;
    line-height: 25.6px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const Description = styled.p`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  color: rgb(${getToken('color-primary-60')});
  font-size: 14px;
  line-height: 22.4px;
  margin: 20px 0;

  text-align: ${conditional({
    center: (props) => props.variant === 'center',
    left: (props) => props.variant === 'left',
  })};

  ${screen.md} {
    font-size: 20px;
    line-height: 32px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 32px;
  }
`;
