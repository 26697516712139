import { useEffect } from 'react';

//styles
import {
  FormWrapper,
  FormDescription,
  FormTitle,
  FormSubtitle,
  HubspotFormContainer,
  CustomContainer,
} from './HubspotFormSimplified.styles';

//assets
import { DisclaimerText } from '@/vendor/HubspotFormIntegration';

// embed form
export const setHubspotFormIntegration = (options) => {
  const script = document.createElement('script');

  script.src = 'https://js.hsforms.net/forms/v2.js';
  script.onload = () => {
    if (window.hbspt) {
      window.hbspt.forms.create(options);
    }
  };

  document.body.appendChild(script);
};

export const HubspotFormSimplified = ({
  content,
  portalId,
  formId,
  sfdcCampaignId,
  target = 'integration-hubspot',
  disclaimer = false,
  theme = 'light',
}) => {
  useEffect(() => {
    setHubspotFormIntegration({
      portalId,
      formId,
      sfdcCampaignId,
      target: `#${target}`,
    });
  }, [formId, portalId, sfdcCampaignId, target]);

  return (
    <FormWrapper theme={theme}>
      <CustomContainer>
        <FormDescription theme={theme}>
          <FormTitle theme={theme}>{content.title}</FormTitle>
          <FormSubtitle theme={theme}>{content.subtitle}</FormSubtitle>
        </FormDescription>
        <HubspotFormContainer theme={theme}>
          <div id={target} />
          {disclaimer && <DisclaimerText />}
        </HubspotFormContainer>
      </CustomContainer>
    </FormWrapper>
  );
};
