//styles
import {
  TitleWrapper,
  Title,
  Subtitle,
  Description,
} from './SectionTitle.styles';

export function SectionTitle({
  title,
  subtitle,
  description,
  variant = 'center',
}) {
  return (
    <TitleWrapper>
      {subtitle && <Subtitle variant={variant}>{subtitle}</Subtitle>}
      {title && <Title variant={variant}>{title}</Title>}
      {description && (
        <Description variant={variant}>{description}</Description>
      )}
    </TitleWrapper>
  );
}
