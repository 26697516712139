import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const SectionWrapper = styled.section`
  padding: 60px 0;
  background-color: ${({ theme }) => theme.color.neutral.lighter3};
  text-align: center;

  ${screen.lg} {
    padding: 100px 0;
  }
`;

export const TitleWrapper = styled.div`
  margin: 8px 0 48px;

  ${screen.md} {
    margin: 12px 0 48px;
  }

  ${screen.lg} {
    margin: 16px 0 48px;
  }

  ${screen.xxlg} {
    margin: 16px 0 64px;
  }
`;

export const MediaWrapper = styled.div`
  position: relative;
  margin: auto;
  max-width: 315px;
  height: 177px;

  ${screen.md} {
    max-width: 690px;
    height: 387px;
  }

  ${screen.lg} {
    max-width: 826px;
    height: 464px;
  }

  ${screen.xxlg} {
    max-width: 1086px;
    height: 610px;
  }
`;

export const Thumbnail = styled.button`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  background: none;

  &:hover {
    picture {
      opacity: 0.9;
    }
    div {
      opacity: 0.9;
    }
  }

  &.inactive {
    display: none;
  }
`;
export const PlayingIcon = styled.div`
  position: absolute;
  display: block;
  width: 56px;
  height: 56px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 10px;
  border: 1px solid #ccc;
  background: rgba(200, 200, 200, 0.4);
  border-radius: 200px;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  opacity: 0.7;

  svg {
    max-width: 90%;
    margin-left: 28%;
    margin-top: 9%;
    height: auto;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }

  ${screen.md} {
    width: 76px;
    height: 76px;
  }
  ${screen.lg} {
    width: 92px;
    height: 92px;
  }
  ${screen.xxlg} {
    width: 120px;
    height: 120px;
  }
`;
export const Picture = styled.picture`
  box-sizing: border-box;
  margin: auto;

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }
`;

export const VideoWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  display: none;

  &.active {
    display: block;
  }

  iframe {
    border-radius: 4px;
    filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));
  }

  ${screen.md} {
    align-self: center;

    iframe {
      border-radius: 8px;
    }
  }
`;

export const ClosingIcon = styled.button`
  width: 36px;
  height: 36px;

  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 20;

  padding: 10px;
  background: rgba(255, 255, 255, 1);
  border-radius: 100px;
  border: none;
  cursor: pointer;
  filter: drop-shadow(0px 0px 44px rgba(0, 0, 0, 0.15));

  opacity: 0.85;

  &:hover {
    opacity: 1;
  }

  ${screen.md} {
    top: -28px;
    right: -28px;

    width: 54px;
    height: 54px;
  }
  ${screen.lg} {
    width: 62px;
    height: 62px;
  }
  ${screen.xxlg} {
    width: 75px;
    height: 75px;
  }
`;
