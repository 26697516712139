import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';
import { Title } from '@/components/Grid';
import { Paragraph1, Paragraph2, Subtitle } from '@/components/Text';

export const WideCalculatorWrapper = styled.section`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.color.neutral.lighter3};
  padding: ${getToken('spacing-20')} 0;
`;

export const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: rgb(${getToken('color-primary-100')});
  padding: ${getToken('spacing-10')} ${getToken('spacing-4')};
  border-radius: ${getToken('border-radius-l')};
  box-shadow: ${getToken('elevation-2')};
  margin: auto;

  ${screen.md} {
    padding: ${getToken('spacing-10')} ${getToken('spacing-8')};
  }

  ${screen.lg} {
    padding: ${getToken('spacing-10')};
  }

  ${screen.xxlg} {
    padding: ${getToken('spacing-20')} ${getToken('spacing-10')};
    max-width: 1156px;
  }
`;

export const SectionHeader = styled.div`
  margin: 0 auto 40px;
`;

export const TitleCustom = styled(Title)`
  text-align: center;
  font-weight: ${getToken('font-weight-medium')};
  font-size: 20px;
  line-height: 30px;
  color: rgb(${getToken('color-text-inverted')});
  text-wrap: balance;

  background: linear-gradient(
    45.01deg,
    rgb(${getToken('color-brand-accent-1')}) 23.29%,
    rgb(${getToken('color-brand-accent-2')}) 88.12%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${screen.lg} {
    font-size: 26px;
    line-height: 39px;
  }
  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const ParagraphCustom = styled(Paragraph1)`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-regular')};
  text-wrap: balance;
  color: rgb(${getToken('color-text-inverted')});
  text-align: center;
`;

export const Paragraph2Custom = styled(Paragraph2)`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-regular')};
  font-size: 14px;
  line-height: 21px;
  color: rgb(${getToken('color-text-inverted')});
  text-wrap: balance;
  text-align: center;

  ${screen.lg} {
    text-align: left;
  }
`;

export const Paragraph3Custom = styled(Paragraph2Custom)`
  font-weight: ${getToken('font-weight-medium')};
  color: rgb(${getToken('color-text-placeholder')});
  margin: 20px 0 0;
  text-align: left;
`;

export const SubtitleCustom = styled(Subtitle)`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 16px;
  line-height: 24px;
  color: rgb(${getToken('color-text-inverted')});
  text-align: center;

  ${screen.lg} {
    text-align: left;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SliderContainer = styled.div`
  margin: auto;
  max-width: 100%;

  ${screen.md} {
    max-width: 365px;
  }

  ${screen.lg} {
    margin: 0;
    max-width: auto;
    min-height: 300px;
  }
`;

export const CalculatorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 34px;
  text-align: center;
  align-items: center;

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    grid-template-columns: auto 510px;
  }

  ${screen.xxlg} {
    grid-template-columns: auto 691px;
    gap: 50px;
  }
`;

export const TableWrapper = styled.div`
  background: rgb(${getToken('color-background-primary')});
  border-radius: ${getToken('border-radius-l')};
  padding: 16px;

  & > div:first-child {
    text-align: center;

    ${screen.lg} {
      text-align: left;
    }
  }

  ${screen.md} {
    padding: 40px;
  }
`;

export const TableContainer = styled.div`
  dl {
    dt {
      font-family: ${getToken('font-family-title')};
      font-weight: ${getToken('font-weight-medium')};

      font-size: 14px;
      line-height: 21px;
      color: rgb(${getToken('color-text-tertiary')});
      text-align: left;
      padding: 0;
      margin: 0;
      display: inline-flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;

      &.separator {
        border-top: 2px solid rgb(${getToken('color-background-inverted')});
        padding-top: 16px;
      }
    }

    dd {
      padding: 0;
      margin: 4px 0 16px;
      font-family: ${getToken('font-family-title')};
      font-weight: ${getToken('font-weight-bold')};

      font-size: 16px;
      line-height: 24px;
      color: rgb(${getToken('color-text-primary')});
      text-align: left;
    }
  }
`;

export const FormWrapper = styled.div`
  form {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;

    ${screen.xxlg} {
      grid-template-columns: 1fr 1fr;
    }
  }

  fieldset {
    text-align: left;
  }

  .hs-input {
    background: rgb(${getToken('color-background-primary')});
    border: 1px solid rgb(${getToken('color-border')});
    border-radius: ${getToken('border-radius-s')};
    padding: ${getToken('spacing-2')} ${getToken('spacing-2')};

    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-regular')};
    font-size: 14px;
    line-height: 21px;
    color: rgb(${getToken('color-text-primary')});
  }

  label {
    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-regular')};
    font-size: 14px;
    line-height: 21px;
    color: rgb(${getToken('color-text-secondary')});
  }

  .hs-button {
    padding: ${getToken('spacing-2')} ${getToken('spacing-12')};
    margin: auto;
    background: rgb(${getToken('color-primary-60')});
    border: 1px solid rgb(${getToken('color-border')});
    border-radius: ${getToken('border-radius-s')};

    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-bold')};
    font-size: 14px;
    line-height: 21px;
    color: rgb(${getToken('color-text-inverted')});
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      background: rgb(${getToken('color-background-inverted')});
      transition: all 0.1s ease-in-out;
    }
  }

  .submitted-message {
    padding: 8px 20px;
    border: 1px solid rgb(${getToken('color-border')});

    p {
      font-family: ${getToken('font-family-default')};
      font-weight: ${getToken('font-weight-regular')};
      font-size: 14px;
      line-height: 21px;
      color: rgb(${getToken('color-text-secondary')});

      text-align: center;

      a {
        text-decoration: underline;
        font-weight: ${getToken('font-weight-bold')};
        color: rgb(${getToken('color-accent-100')});
      }

      ${screen.md} {
        text-align: left;
      }
    }
  }
`;

export const FormHeader = styled.div`
  margin: ${getToken('spacing-8')} 0 ${getToken('spacing-4')};
`;

export const FormTitle = styled(SubtitleCustom)`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  text-align: left;
  color: rgb(${getToken('color-text-secondary')});

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 39px;
  }
`;

export const SliderWrapper = styled.div`
  .rangeslider {
    box-shadow: none;
    background: rgb(${getToken('color-background-primary')});
    height: 8px;

    &__fill {
      background: rgb(${getToken('color-brand')});
    }

    &__handle {
      box-shadow: none;
      border: none;
      background: rgb(${getToken('color-brand')});
      width: 24px;
      height: 24px;

      &::after {
        display: none;
      }
    }
  }
`;

//labels
export const LabelAmount = styled.span`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 48px;
  line-height: 60px;
  color: rgb(${getToken('color-text-inverted')});
  text-align: center;
  display: block;
  margin-top: 24px;

  ${screen.md} {
    text-align: left;
    font-size: 58px;
    line-height: 72.5px;
  }
`;

export const LabelTitle = styled.h5`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-primary')});
  text-align: left;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
`;

export const LabelEstimated = styled.span`
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-bold')};

  font-size: 26px;
  line-height: 39px;
  color: rgb(${getToken('color-success-60')});
  text-align: left;
`;

export const LabelSaving = styled(LabelEstimated)`
  color: rgb(${getToken('color-brand')});
`;

//icons

export const ArrowWrapper = styled.div`
  display: flex;
  height: 100%;
  padding-right: 4px;

  &.green {
    svg {
      color: rgb(${getToken('color-brand')});
      fill: rgb(${getToken('color-brand')});
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  height: 100%;
  padding-left: 4px;
  cursor: help;
  position: relative;
  z-index: 0;

  svg {
    position: unset;
  }

  .popup-info {
    display: none;

    position: absolute;
    cursor default;
    background: rgb(${getToken('color-neutral-90')});
    border-radius: ${getToken('border-radius-s')};
    padding: ${getToken('spacing-2')};
    box-shadow: ${getToken('elevation-2')};

    color: rgb(${getToken('color-text-inverted')});
    font-family: ${getToken('font-family-default')};
    font-weight: ${getToken('font-weight-regular')};
    font-size: 12px;
    line-height: 18.5px;

    width: 300px;
    height: auto;

    top: unset;
    left: unset;
    bottom: 100%;
    right: 0;

    z-index: 4;

    a {
      display: inline-block;
      color: rgb(${getToken('color-accent-20')});
      text-decoration: underline;
    }
  }

  &:hover .popup-info {
    display: block;
  }
`;
